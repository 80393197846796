import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import {SettingsContext} from '../components/userSettingsContext';
import { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef  } from "react"

import {CcStepper2, StepperNavButtons} from "../commonComponents/stepper"
import { IconButton, Dialog, DialogActions, FormGroup, FormControlLabel, Radio, Typography  } from '@mui/material';
import { CcActionButton, CcTitle, CcSubSectionTitle } from "../commonComponents/styledTexts";
import {ExclamationCircleIcon } from '@heroicons/react/20/solid'


import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import axios from 'axios';

import {CcLoader, LionLoaderGradient} from '../commonComponents/generalComponents'
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'
import {CcCheckbox, InputNumber, InputText, InputURL, FileSelector, LionCombo, LionListbox} from '../commonComponents/inputFields'


import { Icon as Iconify } from '@iconify/react';

import { getErrorListFromYupError, getErrorMessage, getErrorMessageForArray, snippetDataValidationSchemaScratch } from '../components/helperErrors'
import { ccColorTeal, ccColorDarkTeal } from '../components/helpersStyles';
import {LionColorPicker}  from '../components/lionColorPicker';
import { lionIcons } from '../components/helpersIcons';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const SvgSelector = forwardRef((props, ref) => {
    /*
  props:
 
  onChange: we don't have an onChange here because it would be to heavy to calculate the pointer png for every input change
            instead the parent can call the "askPointerImg" method to get the latest pointer png when needed
            "askPointerImg" is called by the parent of the parent, check ScratchWinElementEmailManager
 
  */

  const [selectedSvgId, setSelectedSvgId] = useState('')

  const [svgPathStyles, setSvgPathStyles] = useState([])
  const [svgSize, setSvgSize] = useState({width: 0, height: 0})

  useEffect(() => {
    if (props.pointerXml && props.pointerXml.length > 0) {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(props.pointerXml, "image/svg+xml");
      const svgElement = svgDocument.documentElement; 
      setSelectedSvgId(svgElement.getAttribute("id"))
      cloneSvg(svgElement.getAttribute("id"), svgElement)
    }
    else {
      setSelectedSvgId('svg1');
      cloneSvg('svg1')
    }
 
  }, [props.pointerXml])


  useImperativeHandle(ref, () => ({
    async askPointerImg() {
      const result =  getPointerImg();
      return result;
    },
  }))

  
  const getPointerImg = async () => {
    const svgInfo = await svgToPng();
    //const dataParts = svgInfo.b64.split('base64,')
    //const pointerPng = dataParts[1]

    const pointerPng = svgInfo.b64;

    return {b64: pointerPng, pointerXml: svgInfo.xml};
  }

  const svgToPng = () => {
    return new Promise((resolve, reject) => {
    const svgDisplayArea = document.getElementById('svgDisplayArea')
    const svg = svgDisplayArea.getElementsByTagName("svg")[0]
  
      // Extract SVG content as a string
      const svgString = new XMLSerializer().serializeToString(svg);

      // Convert SVG string to a data URL
      const svgDataUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;
  
      // Create a canvas element
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
  
      // Set canvas size to match SVG dimensions
      const width = svg.getAttribute("width");
      const height = svg.getAttribute("height");
      canvas.width = width;
      canvas.height = height;
  
      // Create an Image object
      const img = new Image();
      img.onload = function () {
        // Draw the SVG onto the canvas
        context.drawImage(img, 0, 0);
  
        // Convert the canvas content to an image data URL
        const imageDataUrl = canvas.toDataURL("image/png");
        resolve({b64: imageDataUrl, xml: svgString})
  }
  img.src = svgDataUrl;
    })
}


  const handleEditPathStyle = (index, propName, propValue) => {
    const svgDisplayArea = document.getElementById('svgDisplayArea')
    const paths = svgDisplayArea.getElementsByTagName("path")
    paths[index].setAttribute(propName, propValue)
    //console.log(paths)
    const newPathStyles = [...svgPathStyles]
    newPathStyles[index] = {...svgPathStyles[index], [propName]: propValue}
    setSvgPathStyles(newPathStyles)

  }
  const handleChangeSvgSize = (incr) => {
    const svgDisplayArea = document.getElementById('svgDisplayArea')
    const svg = svgDisplayArea.getElementsByTagName("svg")
    const newWidth = parseInt(svg[0].getAttribute("width"),10) + incr
    const newHeight = parseInt(svg[0].getAttribute("height"),10) + incr

    svg[0].setAttribute("width", newWidth)
    svg[0].setAttribute("height", newHeight)
    setSvgSize({width: newWidth, height: newHeight})

  }
  const cloneSvg = (svgId, svgElement) => {
    //copy the svg tag with svgId and paste it into the pointer customisation area
    const svg = svgElement ? svgElement : document.getElementById(svgId);
    let newSvg = svg.cloneNode(true);
    const svgDisplayArea = document.getElementById('svgDisplayArea')
    svgDisplayArea.replaceChildren(newSvg);
    setSvgSize({width: newSvg.getAttribute('width'), height: newSvg.getAttribute('height')})

    const paths = newSvg.getElementsByTagName("path")

    const newPathStyles = []
    //console.log(paths)
    for (let i =0; i<paths.length; i+=1) {
      const computedStyle = window.getComputedStyle(paths[i]);

      const fill = computedStyle.fill;
      const stroke = computedStyle.stroke;
      newPathStyles.push({fill: fill, stroke: stroke,})  
    }
    //console.log(newPathStyles)
    setSvgPathStyles(newPathStyles)
  }


  return   <div className=''>
            <div className='text-center'>Select a pointer</div>
            <div className='grid grid-cols-3 gap-4 h-[290px] overflow-auto'>
              <div className={`col-span-1 ${selectedSvgId === 'svg1' ? 'bg-ccColorLightTeal' :''} rounded p-1 cursor-pointer  hover:bg-ccColorDarkTeal`}
                  onClick={() => {setSelectedSvgId('svg1'); cloneSvg('svg1')}}
                  >
                <div className='bg-slate-200 rounded h-32 flex items-center justify-center'>
                  <svg id="svg1" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 48 48"><g fill="none" stroke="#000" stroke-linejoin="round" stroke-width="4"><path fill="#2f88ff" d="M41 38H19V44H41V38Z"/><path d="M19.0001 38C12.4812 30.8771 8.74054 26.7492 7.77829 25.6162C6.33491 23.9168 6.941 21.996 10.5532 21.996C14.1653 21.996 16.2485 27.2814 19.0001 27.2814C19.0165 27.2848 19.0176 20.5257 19.0034 7.00404C19.0017 5.3467 20.3438 4.00174 22.0012 4L22.0043 4C23.6635 4 25.0085 5.34501 25.0085 7.00417V15.0136C32.9813 16.2224 37.3158 16.8893 38.0122 17.0144C39.0567 17.2019 41.0001 18.199 41.0001 21.068C41.0001 22.9807 41.0001 27.9581 41.0001 38H19.0001Z" clip-rule="evenodd"/></g></svg>
                </div>
              </div>
              <div className={`col-span-1 ${selectedSvgId === 'svg2' ? 'bg-ccColorLightTeal' :''} rounded p-1 cursor-pointer  hover:bg-ccColorDarkTeal`}
                  onClick={() => {setSelectedSvgId('svg2'); cloneSvg('svg2')}}
                  >
                <div className='bg-slate-200 rounded h-32 flex items-center justify-center'>
                  <svg id="svg2" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 20 20"><path fill={ccColorDarkTeal} d="M17.924 17.315c-.057.174-.193.367-.416.432c-.161.047-5.488 1.59-5.652 1.633c-.469.125-.795.033-1.009-.156c-.326-.287-4.093-2.85-8.845-3.092c-.508-.025-.259-1.951 1.193-1.951c.995 0 3.904.723 4.255.371c.271-.272.394-1.879-.737-4.683L4.438 4.232a1.045 1.045 0 0 1 1.937-.781L8.361 8.37c.193.48.431.662.69.562c.231-.088.279-.242.139-.709L7.144 2.195A1.043 1.043 0 0 1 7.796.871a1.04 1.04 0 0 1 1.325.652l1.946 5.732c.172.504.354.768.642.646c.173-.073.161-.338.115-.569l-1.366-5.471a1.045 1.045 0 1 1 2.027-.506l1.26 5.042c.184.741.353 1.008.646.935c.299-.073.285-.319.244-.522l-.872-4.328a.95.95 0 0 1 1.86-.375l.948 4.711l.001.001v.001l.568 2.825c.124.533.266 1.035.45 1.527c1.085 2.889.519 5.564.334 6.143"/></svg>
                </div>
              </div>
              <div className={`col-span-1 ${selectedSvgId === 'svg3' ? 'bg-ccColorLightTeal' :''} rounded p-1 cursor-pointer  hover:bg-ccColorDarkTeal`}
                  onClick={() => {setSelectedSvgId('svg3'); cloneSvg('svg3')}}
                  >
                <div className='bg-slate-200 rounded h-32 flex items-center justify-center'>
                  <svg id="svg3" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24"><g fill="none" stroke={ccColorDarkTeal} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path fill={ccColorDarkTeal} d="M16.948 9.95L14.998 8v6.587c0 .89-1.077 1.337-1.707.707L11.996 14c-.5-.5-1.701-.8-2.502 0s-.5 2 0 2.5l3.603 4.4A3 3 0 0 0 15.42 22H18a1 1 0 0 0 1-1v-6.1a7 7 0 0 0-2.052-4.95"/><path d="M11 2h2a2 2 0 0 1 2 2v2m-4-4c0 1.333.8 4 4 4m-4-4H9m6 4v6M5 12v2a2 2 0 0 0 2 2h2c0-1.333-.8-4-4-4m0 0V6m4-4H7a2 2 0 0 0-2 2v2m4-4c0 1.333-.8 4-4 4"/><circle cx="10" cy="9" r="1" transform="rotate(90 10 9)"/></g></svg>
                </div>
              </div>
              <div className={`col-span-1 ${selectedSvgId === 'svg4' ? 'bg-ccColorLightTeal' :''} rounded p-1 cursor-pointer  hover:bg-ccColorDarkTeal`}
                  onClick={() => {setSelectedSvgId('svg4'); cloneSvg('svg4')}}
                  >
                <div className='bg-slate-200 rounded h-32 flex items-center justify-center'>
                  <svg id="svg4" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 16 16"><path fill={ccColorDarkTeal} d="M13.204 1.465c.38.167.796.483.796 1.035c0 .538-.39.857-.76 1.031c-.375.175-.87.276-1.399.34C10.781 4 9.381 4 8.026 4H8c-1.386 0-2.727 0-3.721.121q-.753.093-1.095.254a.5.5 0 0 0-.178.117l-.003.004c.012.026.075.13.375.252C3.736 4.895 4.29 5 5 5v1c-.791 0-1.486-.114-2.003-.327C2.526 5.48 2 5.11 2 4.5c0-.538.39-.857.76-1.031c.375-.175.87-.276 1.399-.34C5.219 3 6.619 3 7.974 3H8c1.386 0 2.727 0 3.721-.121q.753-.093 1.095-.254a.5.5 0 0 0 .178-.117l.004-.004l-.002-.002a.6.6 0 0 0-.197-.123q-.37-.164-1.178-.257C10.555 2 9.107 2 7.577 2a.5.5 0 0 1 0-1h.025c1.5 0 3.003 0 4.132.128c.563.064 1.08.164 1.47.337M5.999 6.5a1.5 1.5 0 1 1 3 0v1.57l1.375.21a2.5 2.5 0 0 1 2.05 3.076l-.553 2.218a1.5 1.5 0 0 1-1.23 1.12l-1.9.29c-.608.091-1.173-.197-1.522-.618c-.297-.358-.757-.818-1.349-1.136l-.043-.026l-2.46-1.663a.83.83 0 0 1-.343-.874a1.817 1.817 0 0 1 2.635-1.19l.34.185z"/></svg>
                </div>
              </div>
              <div className={`col-span-1 ${selectedSvgId === 'svg5' ? 'bg-ccColorLightTeal' :''} rounded p-1 cursor-pointer  hover:bg-ccColorDarkTeal`}
                  onClick={() => {setSelectedSvgId('svg5'); cloneSvg('svg5')}}
                  >
                <div className='bg-slate-200 rounded h-32 flex items-center justify-center'>
                  <svg id="svg5" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 56 56"><path fill="currentColor" d="M2.828 35.488c.82-.023 1.406-.656 1.43-1.5C4.516 23.77 9.789 16.223 17.78 13.27l7.735 21.304c.21.586-.024 1.008-.399 1.149c-.351.117-.726 0-1.172-.47l-6.89-7.218c-1.196-1.242-2.696-1.172-3.703-.281c-.961.844-1.243 2.437-.024 3.844l8.25 9.609c6.89 8.04 13.453 9.422 20.531 6.844c9.305-3.375 12.563-11.414 8.86-21.586l-1.547-4.219c-1.406-3.89-4.125-5.578-6.234-4.805c-.47.165-.633.516-.47.961l.68 1.875c.329.89.024 1.5-.492 1.688c-.586.21-1.195-.047-1.523-.938l-.446-1.242c-.82-2.273-2.835-3.234-4.804-2.508c-.89.328-1.149.82-.867 1.594l.914 2.531c.328.891.047 1.5-.492 1.688c-.563.21-1.172-.047-1.5-.938l-.868-2.367c-.914-2.555-2.859-3.21-4.757-2.531c-.844.305-1.149.867-.891 1.594l1.828 5.015c.305.89.023 1.477-.516 1.664c-.562.211-1.172-.047-1.5-.937L21.32 7.668c-.586-1.617-2.039-2.297-3.422-1.805c-1.476.54-2.109 1.969-1.5 3.61l.329.96C7.657 13.81 1.328 22.575 1.328 33.942c0 .844.703 1.57 1.5 1.547"/></svg>
                </div>
              </div>
              <div className={`col-span-1 ${selectedSvgId === 'svg6' ? 'bg-ccColorLightTeal' :''} rounded p-1 cursor-pointer  hover:bg-ccColorDarkTeal`}
                  onClick={() => {setSelectedSvgId('svg6'); cloneSvg('svg6')}}
                  >
                <div className='bg-slate-200 rounded h-32 flex items-center justify-center'>
                  <svg id="svg6" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 56 56"><path fill="currentColor" d="M2.195 36.895c.82-.024 1.407-.657 1.43-1.5c.258-10.196 5.508-17.743 13.477-20.696l5.46 15c.047.117.024.211-.093.258c-.094.047-.164 0-.235-.07l-2.765-3c-1.805-1.946-4.102-2.086-5.883-.586c-2.016 1.71-2.04 4.265-.07 6.68l7.851 9.492c5.93 7.172 12.89 9.398 20.672 6.562c9.305-3.375 13.266-11.906 9.515-22.219l-1.757-4.804c-1.828-5.086-5.32-7.172-9.422-5.742c-1.102-1.43-2.79-1.922-4.617-1.266a7.1 7.1 0 0 0-1.828 1.031c-1.196-1.547-3.047-2.11-4.97-1.43a6.5 6.5 0 0 0-1.476.797L24.812 8.09c-1.007-2.79-3.539-3.961-6.14-3.024c-2.625.961-3.797 3.47-2.79 6.258l.188.516C7 15.238.695 23.98.695 35.348c0 .843.703 1.57 1.5 1.547m38.836 9.07c-6.14 2.25-11.906 1.101-17.32-5.438l-7.852-9.445c-.843-.984-.843-1.945-.093-2.602c.703-.632 1.664-.421 2.437.375l5.414 5.602c.914.938 1.711 1.031 2.485.75c.914-.328 1.312-1.312.937-2.32l-8.297-22.828c-.351-.938.07-1.828.961-2.157c.914-.328 1.758.118 2.11 1.055l5.93 16.29c.28.773 1.148 1.124 1.921.843c.75-.281 1.148-1.102.867-1.852l-2.133-5.882c.329-.305.774-.61 1.22-.774c1.1-.398 2.015.094 2.437 1.242l1.875 5.133c.28.797 1.148 1.102 1.898.82c.727-.258 1.195-1.03.89-1.851l-1.523-4.149a3.55 3.55 0 0 1 1.219-.797c1.102-.398 2.016.094 2.438 1.243l1.242 3.422c.304.82 1.172 1.125 1.922.843a1.446 1.446 0 0 0 .89-1.851l-.937-2.532c1.968-.703 3.82.891 5.085 4.407l1.477 4.008c3.211 8.859.281 15.609-7.5 18.445"/></svg>
                </div>
              </div>
              {/*
              <div className={`col-span-1 ${selectedSvgId === 'svg5' ? 'bg-ccColorLightTeal' :''} rounded p-1 cursor-pointer  hover:bg-ccColorDarkTeal`}
                  onClick={() => {setSelectedSvgId('svg5'); cloneSvg('svg5')}}
                  >
                <div className='bg-slate-200 rounded h-32 flex items-center justify-center'>
                  <svg id="svg5" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 36 36"><path fill="#ffac33" d="M19 0c-1.721 0-3.343.406-4.793 1.111A7 7 0 0 0 13 1a7 7 0 0 0-7 7v12h.018C6.201 26.467 11.489 31.656 18 31.656S29.799 26.467 29.982 20H30v-9c0-6.075-4.925-11-11-11"/><path fill="#ffdc5d" d="M9 5v12c0 4.971 4.029 9 9 9s9-4.029 9-9V5z"/><path fill="#ffac33" d="M26.999 5V4H8v7c6 0 6-6 6-6s2 7 14 7V5z"/><path fill="#ffdc5d" d="M12.421 2.935a1.283 1.283 0 0 0-1.776.367L10 4.284V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v8s0 1 1 1h6l1-1V8.957l2.789-4.246a1.28 1.28 0 0 0-.368-1.776"/><path fill="#ef9645" d="M4 0h1v5H4zm3 0h1v5H7z"/><path fill="#9268ca" d="M27 27H9l1-17H2v17s0 9 8 9h21v-5a4 4 0 0 0-4-4"/><path fill="#c1694f" d="M19 19.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1"/><path fill="#662113" d="M14 16a1 1 0 0 1-1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1-1 1m8 0a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1"/><path fill="#ffdc5d" d="M15 22v6a3 3 0 1 0 6 0v-6z"/><path fill="#df1f32" d="M22 22h-8s1 2 4 2s4-2 4-2"/><path fill="#7450a8" d="M26 32h1v4h-1z"/></svg>
                </div>
              </div>
            */}
            </div>
            <div className='text-center mt-1'>Customise pointer</div>
            <div className='flex flex-row items-center'>
              <div id="svgDisplayArea">
              </div>
              <div>
                <div className='flex flex-row gap-x-1'>
                  <IconButton sx={{color: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleChangeSvgSize(+1)} ><Iconify icon="icomoon-free:enlarge" width="32" height="32" /></IconButton>
                  <IconButton sx={{color: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleChangeSvgSize(-1)} ><Iconify icon="lets-icons:reduce" width="32" height="32" /></IconButton>
                </div>
                <div className='flex flex-row gap-x-1 mt-1'>
                  <div>Size</div>
                  <div className='bg-ccColorYellow rounded text-sm font-semibold p-1'>{`${svgSize.width} x ${svgSize.height} px`}</div>
                </div>
              </div>
            </div>
            <div className='grid grid-cols-3 w-[740px] max-h-80 overflow-auto mt-2 gap-y-8'>
              {svgPathStyles.map((s, index) => <div className='flex flex-col gap-y-2'>
                <div className='flex flex-row items-center gap-x-2'>
                  <div className='w-16'>Fill {index}</div>
                  <LionColorPicker color={s.fill}
                      onChange={(color) => handleEditPathStyle(index, 'fill', color)}
                      />

                </div>
                <div className='flex flex-row items-center gap-x-2'>
                  <div className='w-16'>Stroke {index}</div>
                  <LionColorPicker color={s.stroke}
                      onChange={(color) => handleEditPathStyle(index, 'stroke', color)}
                      />

                </div>
              </div>)}
            </div>       
    </div>

})

export const ScratchWinElementEmailManager = forwardRef((props, ref) => {
  /*
  props:
  templateId: (must be provided for new )
  snippetInfo: (must be provided for edits )

  onChange: we don't have an onChange here because it would be to heavy to calculate the pointer png for every input change
            instead the parent can call the "askPointerImg" method to get the latest pointer png when needed
  
  askPointerImg: exposed method to call by the parent to get the pointer image
  */

  const {settings, setSettings} = useContext(SettingsContext);

  const [snippetSettings, setSnippetSettings] = useState({emailEffectType: 'coverOnly'})

  const [partialRevealBrushProps, setPartialRevealBrushProps] = useState({radius: 24, fadeWidth: 8})
  const svgSelectorRef = useRef();


  useEffect(() => {
    if (props.snippetInfo) { //we are in edit
      //console.log(props.snippetInfo.snippetSettings)
      setSnippetSettings(props.snippetInfo.snippetSettings)
      if (props.snippetInfo.snippetSettings.emailEffectType === 'coverAndPartialReveal' && props.snippetInfo.snippetSettings.partialRevealSettings) {
        if (Object.keys(props.snippetInfo.snippetSettings.partialRevealSettings.metadata).length > 0) setPartialRevealBrushProps(props.snippetInfo.snippetSettings.partialRevealSettings.metadata)
      }
 
    }
    else {
      
    }

  }, [props.templateId, props.snippetInfo, settings])


  useEffect(() => {
    //if (props.snippetInfo.snippetSettings.svgId === undefined || props.snippetInfo.snippetSettings.svgId === '') {
    if (snippetSettings.emailEffectType === 'coverAndTeaser') {
    }   
    //console.log(myDivRef.current)
    //setSelectedSvgId('svg1')
    //cloneSvg('svg1')
    if (snippetSettings.emailEffectType === 'coverAndPartialReveal') {
      const radius = partialRevealBrushProps.radius;
      const fadeWidth = partialRevealBrushProps.fadeWidth;
      const centerX = radius + fadeWidth;
      const centerY = radius + fadeWidth;
      // const color = { r: 255, g: 0, b: 0 }; // Red color
      const color = hexToRgb(ccColorDarkTeal)
      drawFadingCircle('circleCanvas', centerX, centerY, radius, fadeWidth, color);
    }   

  }, [snippetSettings.emailEffectType])

  const handleChangePartialRevealBrushSize = (incr) => {
    const radiusFadeRatio = partialRevealBrushProps.radius / partialRevealBrushProps.fadeWidth;
    const newRadius = partialRevealBrushProps.radius + incr;
    const newFadeWidth = parseInt(newRadius / radiusFadeRatio)
    setPartialRevealBrushProps({radius: newRadius, fadeWidth: newFadeWidth})

    const centerX = newRadius + newFadeWidth;
    const centerY = newRadius + newFadeWidth;
    // const color = { r: 255, g: 0, b: 0 }; // Red color
    const color = hexToRgb(ccColorDarkTeal)
    drawFadingCircle('circleCanvas', centerX, centerY, newRadius, newFadeWidth, color);

  }
  const handleChangePartialRevealBrushFadeWidth = (incr) => {
    const radius = partialRevealBrushProps.radius;
    const newFadeWidth = partialRevealBrushProps.fadeWidth + incr;
    setPartialRevealBrushProps({radius: radius, fadeWidth: newFadeWidth})

    const centerX = radius + newFadeWidth;
    const centerY = radius + newFadeWidth;
    // const color = { r: 255, g: 0, b: 0 }; // Red color
    const color = hexToRgb(ccColorDarkTeal)
    drawFadingCircle('circleCanvas', centerX, centerY, radius, newFadeWidth, color);

  }

  const rgbToHex = (rgb) => {
    return `#${rgb.match(/\d+/g).map(x => (+x).toString(16).padStart(2, "0")).join("")}`;
  }
  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  useImperativeHandle(ref, () => ({
    async askPointerImg() {
      const result =  getPointerImg();
      return result;
    },
    async askBrushB64() {
      const result = getBrushB64();
      return result;
    },
  }))

  const getBrushB64 = () => {
    const canvas = document.getElementById('circleCanvas');
  
    const imageDataUrl = canvas.toDataURL("image/png");
    //const dataParts = imageDataUrl.split('base64,')
    //const result = dataParts[1]
  
    const result = imageDataUrl;
    return {b64: result, metadata: partialRevealBrushProps}
  }
  
  const getPointerImg = async () => {
    const result = await svgSelectorRef.current.askPointerImg();

    return result;
  }

const handleChangeSnippetSettings = (propName, propValue) => {
  const newSettings = {...snippetSettings, [propName]: propValue}
  setSnippetSettings(newSettings)

  if (props.onChange) props.onChange(newSettings);
}

const drawFadingCircle = (canvasId, centerX, centerY, radius, fadeWidth, color) => {
  const canvas = document.getElementById(canvasId);
  canvas.width = 2 * (radius + fadeWidth)
  canvas.height = 2 * (radius + fadeWidth)
  const ctx = canvas.getContext('2d');
  //ctx.clearRect(0, 0, canvas.width, canvas.height);


  const gradient = ctx.createRadialGradient(centerX, centerY, radius, centerX, centerY, radius + fadeWidth);
  
  // Fully opaque inside the circle
  gradient.addColorStop(0, `rgba(${color.r}, ${color.g}, ${color.b}, 1)`);
  
  // Gradual fade to transparency
  gradient.addColorStop(1, `rgba(${color.r}, ${color.g}, ${color.b}, 0)`);

  // Draw the circle with fading effect
  ctx.fillStyle = gradient;
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius + fadeWidth, 0, Math.PI * 2);
  ctx.closePath();
  ctx.fill();
}


  return <div className='text-ccColorDarkTeal p-2 flex flex-col gap-y-2'>
    <CcSubSectionTitle>What you see in the email</CcSubSectionTitle>
    <div class="inline-flex rounded-md shadow-sm font-poppins" role="group">
      <div className={`cursor-pointer px-4 py-2 w-80 font-medium border border-gray-200 focus:outline-none focus:ring-0 rounded-s-lg hover:bg-gray-100 hover:text-ccColorTeal ${snippetSettings.emailEffectType === 'coverOnly' ? 'bg-gray-200' : 'bg-white'}`}
            onClick={()=>handleChangeSnippetSettings('emailEffectType', 'coverOnly')}>
        <div>
          Cover image only
        </div>
        <div className='text-sm mt-2 font-normal'>Display your cover image as a static image. </div>
      </div>
      <div className={`cursor-pointer px-4 py-2 w-80 font-medium border-t border-b border-gray-200 focus:outline-none focus:ring-0 hover:bg-gray-100 hover:text-ccColorTeal ${snippetSettings.emailEffectType === 'coverAndPartialReveal' ? 'bg-gray-200' : 'bg-white'}`}
          onClick={()=>handleChangeSnippetSettings('emailEffectType', 'coverAndPartialReveal')}>
        <div>
          Cover image with partial reveal
        </div>
        <div className='text-sm mt-2 font-normal'>Create an animated gif which partially reveals your background image.</div>
      </div>
      <div className={`cursor-pointer px-4 py-2 w-80 font-medium border border-gray-200 focus:outline-none focus:ring-0 rounded-e-lg hover:bg-gray-100 hover:text-ccColorTeal ${snippetSettings.emailEffectType === 'coverAndTeaser' ? 'bg-gray-200' : 'bg-white'}`}
          onClick={()=>handleChangeSnippetSettings('emailEffectType', 'coverAndTeaser')}>
        <div>
          Cover image with teasing effect
        </div>
        <div className='text-sm mt-2 font-normal'>Create an animated gif with a pointer to tease the scratch off effect.</div>
      </div>
    </div>

  {snippetSettings.emailEffectType === 'coverAndTeaser' &&
    <div className='mt-2'>

    <SvgSelector pointerXml={snippetSettings.pointerXml} ref={svgSelectorRef} />
    </div>
  }
  {snippetSettings.emailEffectType === 'coverAndPartialReveal' && <div className='mt-2'>
    <div className='text-lg font-semibold'>Configure brush</div>
    <div className='flex flex-row gap-x-4'>
      <div>
        <canvas id="circleCanvas"></canvas>
      </div>
      <div>
        <div>Total size</div>
        <div className='flex flex-row gap-x-1'>
          <IconButton sx={{color: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleChangePartialRevealBrushSize(+1)} ><Iconify icon="icomoon-free:enlarge" width="32" height="32" /></IconButton>
          <IconButton sx={{color: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleChangePartialRevealBrushSize(-1)} ><Iconify icon="lets-icons:reduce" width="32" height="32" /></IconButton>
        </div>
        <div className='flex flex-row gap-x-1 mt-1'>
          <div className='bg-ccColorYellow rounded text-sm font-semibold p-1'>{`${2 * (partialRevealBrushProps.radius + partialRevealBrushProps.fadeWidth)} x ${2 * (partialRevealBrushProps.radius + partialRevealBrushProps.fadeWidth)} px`}</div>
        </div>
      </div>

      <div className='ml-2'>
        <div>Fade Width</div>
        <div className='flex flex-row gap-x-1'>
          <IconButton sx={{color: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleChangePartialRevealBrushFadeWidth(+1)} ><Iconify icon="icomoon-free:enlarge" width="32" height="32" /></IconButton>
          <IconButton sx={{color: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleChangePartialRevealBrushFadeWidth(-1)} ><Iconify icon="lets-icons:reduce" width="32" height="32" /></IconButton>
        </div>
        <div className='flex flex-row gap-x-1 mt-1'>
          <div className='bg-ccColorYellow rounded text-sm font-semibold p-1'>{`${partialRevealBrushProps.fadeWidth} px`}</div>
        </div>
      </div>

  </div>

  </div>
  }
    </div>

})


export function ScratchWinElementAssetsManager(props) {
  /*
  props:
  templateId: (must be provided for new )
  snippetInfo: (must be provided for edits )

  onChange: returns all the snippet info
  */

  const {settings, setSettings} = useContext(SettingsContext);

  const [snippetName, setSnippetName] = useState('')
  const [snippetDesc, setSnippetDesc] = useState('')
  const [snippetSettings, setSnippetSettings] = useState({teaserImgB64: '', revealImages: [{imgB64: '', href:''}]})
  const [originalTeaserImgB64, setOriginalTeaserImgB64] = useState('')

  //const [svgPathStyles, setSvgPathStyles] = useState([])
  //const [svgSize, setSvgSize] = useState({width: 0, height: 0})

  const [generatingTeaser, setGeneratingTeaser] = useState(false)

  useEffect(() => {
    if (props.snippetInfo) { //we are in edit
      //console.log(props.snippetInfo)
      setSnippetName(props.snippetInfo.snippetName)
      setSnippetDesc(props.snippetInfo.snippetDesc)
      setSnippetSettings(props.snippetInfo.snippetSettings)
    }
    else {
      
    }

  }, [props.templateId, props.snippetInfo, settings])


  const prepareDataToPost = (updatePropName, updatePropValue) => {
    const result = {snippetId: 0, snippetName: '', snippetDesc: '', 
    labels: [{labelId: 0, labelName: ''}], href: '',
    snippetTypeId: 15,
    filters: [], orderBy: [],
    dataConnectionId: 0, templateId: 0, dataConnectionInfo: {}};

    result.snippetName = snippetName;
    result.snippetDesc = snippetDesc;
    result.snippetSettings = snippetSettings;
    result.webPageSettings = props.snippetInfo.webPageSettings;

    if (updatePropName) {
      result[updatePropName] = updatePropValue;
    }
      //console.log(result)
      return result;
  }

  const handleSnippetNameChange = (newValue) => {
    setSnippetName(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetName', newValue))
  }
  const handleSnippetDescChange = (newValue) => {
    setSnippetDesc(newValue)
    if (props.onChange) props.onChange(prepareDataToPost('snippetDesc', newValue))
  }

  const handleImageChange = (dataURL) => {
    let imgB64 = ''

    /*
    if (dataURL && dataURL !== '') {
      const dataParts = dataURL.split('base64,')
      imgB64 = dataParts[1]
    }
     */
    imgB64 = dataURL;
    const newSnippetSettings = {...snippetSettings, teaserImgB64: imgB64 }
    setSnippetSettings(newSnippetSettings)
    setOriginalTeaserImgB64(imgB64)
    if (props.onChange) props.onChange(prepareDataToPost('snippetSettings', newSnippetSettings))

  }
  const handleRevealImageChange = (dataURL, index) => {
    let imgB64 = ''

    /*
    if (dataURL && dataURL !== '') {
      const dataParts = dataURL.split('base64,')
      imgB64 = dataParts[1]
    }
   */
    imgB64 = dataURL;

    const newRevealImages = [...snippetSettings.revealImages]
    const newRevealImage = {...newRevealImages[index], imgB64: imgB64}
    newRevealImages.splice(index, 1, newRevealImage);

    const newSnippetSettings = {...snippetSettings, revealImages: newRevealImages }
    setSnippetSettings(newSnippetSettings)
    if (props.onChange) props.onChange(prepareDataToPost('snippetSettings', newSnippetSettings))

  }
  const handleRevealHrefChange = (href, index) => {
    const newRevealImages = [...snippetSettings.revealImages]
    const newRevealImage = {...newRevealImages[index], href: href}
    newRevealImages.splice(index, 1, newRevealImage);

    const newSnippetSettings = {...snippetSettings, revealImages: newRevealImages }
    setSnippetSettings(newSnippetSettings)
    if (props.onChange) props.onChange(prepareDataToPost('snippetSettings', newSnippetSettings))

  }


const handleAddReveal = () => {
  const newRevealImages = [...snippetSettings.revealImages]
  
  newRevealImages.push({imgB64: '', href:''})

  const newSnippetSettings = {...snippetSettings, revealImages: newRevealImages }
  setSnippetSettings(newSnippetSettings)
  if (props.onChange) props.onChange(prepareDataToPost('snippetSettings', newSnippetSettings))
}

const handleDeleteReveal = (index) => {
  const newRevealImages = [...snippetSettings.revealImages]
  
  newRevealImages.splice(index, 1)

  const newSnippetSettings = {...snippetSettings, revealImages: newRevealImages }
  setSnippetSettings(newSnippetSettings)
  if (props.onChange) props.onChange(prepareDataToPost('snippetSettings', newSnippetSettings))

}

  return <div className='text-ccColorDarkTeal p-2 flex flex-col'>
    <div className='flex flex-row gap-x-4'>
      <div className="w-96">
        <InputText
                displayName="Name"
                value={snippetName}
                onChange={(val) => {handleSnippetNameChange(val);}}
                error={getErrorMessage(props.validationErrorsList, 'snippetName')}
        />
      </div>

      <div className="w-96">
        <InputText
                  displayName="Description"
                  value={snippetDesc}
                  onChange={(val) => {handleSnippetDescChange(val);}}
                  error={getErrorMessage(props.validationErrorsList, 'snippetDesc')}
        />
      </div>
    </div>
  
      <div className='mt-4 border rounded p-2 h-[330px] flex flex-row items-center gap-x-32'>
        <FileSelector noDeleteButton
          text="Upload cover image"
          onChange={handleImageChange}
          base64={props.action ? props.action.imgB64 : undefined}
        />
        {snippetSettings.teaserImgB64 === '' && <div className='bg-slate-200 rounded p-4 mt-2 w-[400px] h-[300px] flex flex-col justify-center items-center'>
              <Iconify icon="carbon:no-image" width={200} />
              <div className='font-poppins font-semibold'>No image uploaded</div>
          </div>

        }
        {generatingTeaser &&
          <div className='mt-4 flex justify-center'><CcLoader size={96} /></div>
        }
        {snippetSettings.teaserImgB64 !== '' && !generatingTeaser &&
          <div className='bg-slate-200 rounded p-4 mt-2 w-[400px] h-[300px] flex justify-center'>
            <div className='relative h-full' >

                <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`${snippetSettings.teaserImgB64}`} />
                {/*<img className='max-w-none' src={`data:image;base64,${snippetSettings.teaserImgB64}`} />*/}


            </div>
          </div>
        }
        {getErrorMessage(props.validationErrorsList, 'snippetSettingsCoverImg') && getErrorMessage(props.validationErrorsList, 'snippetSettingsCoverImg').length > 0 && <div className='flex flex-row items-center text-red-500 gap-x-1'>
          <ExclamationCircleIcon className="h-5 w-5" aria-hidden="true" />

          {getErrorMessage(props.validationErrorsList, 'snippetSettingsCoverImg')}
          </div>
        }

      </div>

      {snippetSettings.revealImages.map((rImg, index) =>  <div className='mt-4 border rounded p-2 h-[330px] flex flex-row items-center gap-x-32'>
        <FileSelector noDeleteButton
          text="Upload reveal image"
          onChange={(dataURL) => handleRevealImageChange(dataURL, index)}
        />
        {rImg.imgB64 === '' && <div className='bg-slate-200 rounded p-4 mt-2 w-[400px] h-[300px] flex flex-col justify-center items-center'>
              <Iconify icon="carbon:no-image" width={200} />
              <div className='font-poppins font-semibold'>No image uploaded</div>
          </div>

        }
        {rImg.imgB64 !== '' &&
          <div className='bg-slate-200 rounded p-4 mt-2 w-[400px] h-[300px] flex justify-center'>
            <div className='relative h-full' >

                <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`${rImg.imgB64}`} />
                {/*<img className='max-w-none' src={`data:image;base64,${snippetSettings.teaserImgB64}`} />*/}


            </div>
          </div>
        }
        <div>
          {getErrorMessageForArray(props.validationErrorsList, 'snippetSettingsRevealImg', index) && getErrorMessageForArray(props.validationErrorsList, 'snippetSettingsRevealImg', index).length > 0 && <div className='flex flex-row items-center text-red-500 gap-x-1 mb-8'>
            <ExclamationCircleIcon className="h-5 w-5" aria-hidden="true" />

            {getErrorMessageForArray(props.validationErrorsList, 'snippetSettingsRevealImg', index)}
            </div>
          }
{/* 
          <div className="w-96">
            <label htmlFor="Endpoint" className="block text-sm font-medium text-ccColorDarkTeal font-poppins">
              Href
            </label>
            <InputURL
              //displayName="Name"
              value={rImg.href}
              onChange={(val) => {handleRevealHrefChange(val, index);}}
              error={getErrorMessageForArray(props.validationErrorsList, 'snippetSettingsRevealHref', index)}
              />
          </div>
*/}
        </div>

        {snippetSettings.revealImages.length > 1 && <>
        <div className='flex grow'></div>
            <IconButton onClick={() => handleDeleteReveal(index)}>
              <Iconify icon={lionIcons.Icon_Actions_Delete} width={32} />
            </IconButton>
          
          </>
        }
      </div>)
      }

      <CcActionButton className="w-fit mt-4"
        onClick={handleAddReveal}
      >Add another reveal image</CcActionButton>
    </div>

}

export const ScratchWinElementWebPageManager = forwardRef((props, ref) => {

  const [templates, setTemplates] = useState([])
  const [selectedTemplateId, setSelectedTemplateId] = useState(0)
  const [templatePropValuesCollection, setTemplatePropValuesCollection] = useState([]) //[{propName: '', propValue: ''}]
  const [fonts, setFonts] = useState([])
  const [coverType, setCoverType] = useState('solidColor') //can be: 'solidColor', 'useEmailCover', 'image' 
  const [coverSolidColor, setCoverSolidColor] = useState('#b3b3b3')
  //const [coverAddEffect, setCoverAddEffect] = useState(true)
  const [timeZones, setTimeZones] = useState(undefined);

  const svgSelectorRef = useRef();


  useEffect(() => {
    if (props.snippetInfo) {

      if (props.snippetInfo.webPageSettings) {
        //console.log(props.snippetInfo.webPageSettings)
        setSelectedTemplateId(props.snippetInfo.webPageSettings.selectedWebPageId)
        setTemplatePropValuesCollection(props.snippetInfo.webPageSettings.templatePropValuesCollection)
        setCoverType(props.snippetInfo.webPageSettings.coverType)
        setCoverSolidColor(props.snippetInfo.webPageSettings.coverSolidColor)
        //setCoverAddEffect(props.snippetInfo.webPageSettings.coverAddEffect)
      }
      else {

      }
    }
    if (templates.length === 0) {
      let initialTemplateId = 1
      if (props.snippetInfo && props.snippetInfo.webPageSettings) initialTemplateId = props.snippetInfo.webPageSettings.selectedWebPageId
      getTemplates(initialTemplateId);
    }
    if (fonts.length === 0) getFonts();
  
  }, [props.snippetInfo])

  useEffect(() => {
    getTimezones()
  }, [])

  const getTemplates = async (initialTemplateId) => {
    try {
      let url = `${process.env.REACT_APP_LION_API_URL}/WebPages/Templates/15`;

      const response = await axios.get(url);
      setTemplates(response.data);
      setSelectedTemplateId(initialTemplateId)
      if (props.snippetInfo.webPageSettings.templatePropValuesCollection.length === 0) initTemplatePropValuesCollection(initialTemplateId, response.data)
      
    } catch (error) {
      //console.log(error);
    }

  }
  const getFonts = async () => {
    try {
      let url = `${process.env.REACT_APP_LION_API_URL}/WebPages/GoogleFontsList`;

      const response = await axios.get(url);
      setFonts(response.data);
      
    } catch (error) {
      //console.log(error);
    }

  }
  const doOnChange = (templateId, values) => {
    if (props.onChange) {
      const result = {selectedWebPageId: templateId, templatePropValuesCollection: values, coverType: coverType, coverSolidColor: coverSolidColor,
                     coverAddEffect: props.snippetInfo.webPageSettings.coverAddEffect, expiryDate: props.snippetInfo.webPageSettings.expiryDate, expiryTimezoneId: props.snippetInfo.webPageSettings.expiryTimezoneId }
      props.onChange(result)
    }
  }
  const doOnChangeWithNewValue = (propName, propValue) => {
    if (props.onChange) {
      const result = {selectedWebPageId: selectedTemplateId, templatePropValuesCollection: templatePropValuesCollection, coverType: coverType, coverSolidColor: coverSolidColor,
                     coverAddEffect: props.snippetInfo.webPageSettings.coverAddEffect, expiryDate: props.snippetInfo.webPageSettings.expiryDate, expiryTimezoneId: props.snippetInfo.webPageSettings.expiryTimezoneId}
      result[propName] = propValue;
      props.onChange(result)
    }
  }

  useImperativeHandle(ref, () => ({
    async askPointerImg() {
      const result =  getPointerImg();
      return result;
    },
  }))

  const getPointerImg = async () => {
    const result = await svgSelectorRef.current.askPointerImg();

    return result;
  }


  const initTemplatePropValuesCollection = (templateId, _templates) => {
    let activeTemplate = undefined;
    if (_templates) activeTemplate = _templates.find(t => t.webPageTemplateId === templateId)
    else activeTemplate = templates.find(t => t.webPageTemplateId === templateId)

    if (_templates !== undefined) {
      const result = []
      activeTemplate.placeHolders.forEach(p => {result.push({propName: p.codeName, propValue: p.defaultValue})})
      result.push({propName: 'brushStrokeWidth',  propValue: '35'})
      result.push({propName: 'timeoutToReveal',  propValue: '1600'})
      doOnChange(templateId, result)
      return result;
    }
    return templatePropValuesCollection;
  }

  const getTemplatePropValue = (propName) => {
    //retrieve the prop value (give its name) from the templatePropValuesCollection
    const matchedProp = templatePropValuesCollection.find(pv => pv.propName === propName);
    
    if (matchedProp && matchedProp.propValue !== '') return matchedProp.propValue;
    else {
      //we try to get the default value if available
      const activeTemplate = templates.find(t => t.webPageTemplateId === selectedTemplateId)
      if (activeTemplate === undefined) return ''
      const currentPlaceholder = activeTemplate.placeHolders.find(p => p.codeName === propName)
      if (currentPlaceholder === undefined) return ''
      return currentPlaceholder.defaultValue ?? ''
    }
    
  }
  const setTemplatePropValue = (propName, propValue) => {
    //retrieve the prop value (give its name) from the templatePropValuesCollection
    const matchedIndex = templatePropValuesCollection.findIndex(pv => pv.propName === propName);
    const newValues = [...templatePropValuesCollection];

    //newValues.splice(matchedIndex, 1, {propName: propName, propValue: propValue})
    //setTemplatePropValuesCollection(newValues)
    //console.log(newValues)
    
    if (matchedIndex === -1) {
      newValues.push({propName: propName, propValue: propValue})
      setTemplatePropValuesCollection(newValues)
    }
    else {
      newValues.splice(matchedIndex, 1, {propName: propName, propValue: propValue})
      setTemplatePropValuesCollection(newValues)
    }
    
    doOnChange(selectedTemplateId, newValues)
  }

  const handleTimezoneChange = (timezone) => {
    doOnChangeWithNewValue('expiryTimezoneId', timezone.id)

  }
 
  const renderLayoutSettings = () => {
    if (selectedTemplateId === 0) return <></>
    if (templates.length === 0) return <></>

    const selectedTemplate = templates.find(t => t.webPageTemplateId === selectedTemplateId);
    return selectedTemplate.placeHolders.map(prop => <div className='col-span-1'>
              {prop.type === 'text' && 
              <InputText
                displayName={prop.prettyName}
                value={getTemplatePropValue(prop.codeName)}
                onChange={(val) => {setTemplatePropValue(prop.codeName, val);}}
              />
              }
              {prop.type === 'number' && 
                          <div className='w-[200px] mt-4'>
                            <InputNumber
                                displayName={prop.prettyName}
                                value={getTemplatePropValue(prop.codeName)}
                                onChange={(val) => {setTemplatePropValue(prop.codeName, val.toString());}}
                                  />
                          </div>
              }
              {prop.type === 'color' && <div>
                            <div  className="block text-sm font-poppins font-medium leading-6 text-gray-900">
                              {prop.prettyName}
                            </div>
                            <LionColorPicker //color={p.defaultValue}
                              //onChange={(color) => {handleModelChange(p.propName, color)}}
                              color={getTemplatePropValue(prop.codeName)}
                              onChange={(val) => {setTemplatePropValue(prop.codeName, val);}}
                            />

              </div>
              }
              {prop.type === 'image' && <div>
                           <div  className="block text-sm font-poppins font-medium leading-6 text-gray-900">
                              {prop.prettyName}
                            </div>
                            <div className='flex flex-row gap-x-2 items-center'>
                              <FileSelector noDeleteButton
                                text="Upload"
                                onChange={(dataURL) => setTemplatePropValue(prop.codeName, dataURL)}
                              />
                              {getTemplatePropValue(prop.codeName).length === 0 && 
                                <div>Not used</div>
                              }
                              {getTemplatePropValue(prop.codeName).length > 0 && 
                                          <div className='bg-slate-200 rounded p-4 mt-2 max-w-[380px] max-h-[230px] flex justify-center'>
                                          <div className='relative h-full' >
                                              <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`${getTemplatePropValue(prop.codeName)}`} />                              
                                          </div>
                                        </div>                              
                              }
                            </div>
                </div>
              }
              {prop.type === 'font' && <div>
                  <div  className="block text-sm font-poppins font-medium leading-6 text-gray-900">
                    {prop.prettyName}
                  </div>
                  <GoogleFontsSelector
                        fonts={fonts}
                        selected={getTemplatePropValue(prop.codeName)}
                        onChange={(val) => {setTemplatePropValue(prop.codeName, val);}}
                  />
              </div>

              }

    </div>)
  }

  const getTimezones = async () => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Settings/timezones`;
      const response = await axios.get(url);
      const data = response.data;

      const result = data.map(d => {return {id: d.zoneId, name: d.zoneName}});
      setTimeZones(result);

    } catch (error) {
      //console.log(error);
    }
  }
  const handleTimerDateChange = (newDate) => {
    //newDate.$d.setSeconds(0); //we don't allow to set seconds, for safety we always set them to 0
    //console.log(newDate)
    //console.log(newDate.toJSON())
    doOnChangeWithNewValue('expiryDate', newDate.toJSON())
  }

  const handleRevealHrefChange = (href, index) => {
    const newRevealImages = [...props.snippetInfo.snippetSettings.revealImages]
    const newRevealImage = {...newRevealImages[index], href: href}
    newRevealImages.splice(index, 1, newRevealImage);

    if (props.onChangeRevealImages) props.onChangeRevealImages(newRevealImages)
  }
  const handleRevealMappingChange = (mappingValue, index) => {
    const newRevealImages = [...props.snippetInfo.snippetSettings.revealImages]
    const newRevealImage = {...newRevealImages[index], mapping: mappingValue}
    newRevealImages.splice(index, 1, newRevealImage);

    if (props.onChangeRevealImages) props.onChangeRevealImages(newRevealImages)

  }

  return <div className='text-ccColorDarkTeal'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='text-lg font-poppins font-bold hidden'>Select a layout</div>
      <div className='grid grid-cols-3 gap-4 h-[160px] overflow-auto hidden'>
        {templates.map(t => <div className={`h-fit p-1 col-span-1 rounded ${selectedTemplateId === t.webPageTemplateId ? 'bg-ccColorLightTeal' :''} hover:bg-ccColorDarkTeal`}> 
            <div className={`relative  p-1 h-32 cursor-pointer  bg-slate-200 rounded flex items-center justify-center `}
              onClick={() => {setSelectedTemplateId(t.webPageTemplateId); /*setTemplatePropValuesCollection(initTemplatePropValuesCollection(t.webPageTemplateId));*/}}
              >
                  <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={t.thumbnailB64} alt='' />    
            </div>
          </div>)
        }
      </div>
    <div className='bg-slate-100 border rounded p-2'>
      <div className='text-lg font-poppins font-bold mb-2'>Configure</div>
      <div className='grid grid-cols-2 gap-x-4 gap-y-4'>
        {renderLayoutSettings()}
      </div>
      <div className='flex flex-row gap-x-4'>
        <div className='w-[200px] mt-4'>
          <InputNumber
                displayName="Touch size"
                min={20} max={100}
                value={getTemplatePropValue('brushStrokeWidth')}
                onChange={(val) => {setTemplatePropValue('brushStrokeWidth', val.toString());}}
              />
        </div>
        <div className='w-[200px] mt-4'>
          <InputNumber
                displayName="Timeout to reveal"
                min={500} max={10000} incr={200}
                value={getTemplatePropValue('timeoutToReveal')}
                onChange={(val) => {setTemplatePropValue('timeoutToReveal', val.toString());}}
              />
        </div>
      </div>

    </div>
    <div className='bg-slate-100 border rounded p-2 mt-6 font-poppins'>
      <div className='text-lg font-bold mb-2'>Expiry</div>
      <div className='flex flex-row items-center gap-x-4'>
        <div>
        <label htmlFor="timerDate" className="block text-sm font-medium leading-6 text-gray-900">
          Date
        </label>
        <div className="mt-1">
        <DateTimePicker
              value={dayjs(props.snippetInfo.webPageSettings.expiryDate)}
              ampm={false}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              onChange={handleTimerDateChange}
            />
        </div>
        </div>        
        <div>
          <label htmlFor="snippetDescription" className="block text-sm font-medium leading-6 text-gray-900">
            Time zone
          </label>
          <div className="mt-1">
            <LionCombo items={timeZones}
              onChange={handleTimezoneChange}
              selectedItemId={props.snippetInfo.webPageSettings.expiryTimezoneId}
              zIndex={100}
            />
          </div>
        </div>

      </div>
    </div>
    <div className='bg-slate-100 border rounded p-2 mt-6 font-poppins'>
      <div className='text-lg font-bold mb-2'>Landing Page</div>

      {props.snippetInfo !== undefined && props.snippetInfo.snippetSettings.revealImages.length === 1 && <div>
        <div className="w-[450px] font-roboto">
            <label htmlFor="Endpoint" className="block text-sm font-medium text-ccColorDarkTeal font-poppins">
              Href
            </label>
            <InputURL
              //displayName="Name"
              value={props.snippetInfo.snippetSettings.revealImages[0].href}
              onChange={(val) => {handleRevealHrefChange(val, 0);}}
              error={getErrorMessageForArray(props.validationErrorsList, 'snippetSettingsRevealHref', 0)}
              />
          </div>

      </div>
      }

      {props.snippetInfo !== undefined && props.snippetInfo.snippetSettings.revealImages.length > 1 && props.snippetInfo.snippetSettings.revealImages.map((rImg, index) => <div className='flex flex-row gap-x-4 items-center'>
        <div className='bg-slate-200 rounded p-4 mt-2 w-[200px] h-[150px] flex justify-center'>
            <div className='relative h-full' >
                <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`${rImg.imgB64}`} />
            </div>
          </div>

        <div className="w-96 font-roboto">
            <label htmlFor="Endpoint" className="block text-sm font-medium text-black font-poppins">
              Href
            </label>
            <InputURL
              //displayName="Name"
              value={rImg.href}
              onChange={(val) => {handleRevealHrefChange(val, index);}}
              error={getErrorMessageForArray(props.validationErrorsList, 'snippetSettingsRevealHref', index)}
              />
        </div>
        <div>
          <InputText
            displayName="URL parameter mapping"
            value={rImg.mapping}
            onChange={(val) => {handleRevealMappingChange(val, index);}}
        />
        </div>

      </div>)
      }
    </div>

    <div className='bg-slate-100 border rounded p-2 mt-6 font-poppins'>
      <div className='text-lg font-bold mb-2'>Cover</div>
      <div className='flex flex-row gap-x-4 items-start'>
      <div className='flex flex-row items-center' onClick={() => {setCoverType('useEmailCover'); doOnChangeWithNewValue('coverType', 'useEmailCover')}}>
      <Radio
            checked={coverType === 'useEmailCover'}
            onChange={() => setCoverType('useEmailCover')}
            value="useEmailCover"
            name="radio-coverType"
            inputProps={{ 'aria-label': 'A' }}
            sx={{
              '&.Mui-checked': {
                color: ccColorTeal,
              },
            }}
          />
          <div>Use email cover</div>
        </div>
        <div>
          <div className='flex flex-row items-center' onClick={() => {setCoverType('solidColor'); doOnChangeWithNewValue('coverType', 'solidColor')}}>
            <Radio
                checked={coverType === 'solidColor'}
                onChange={() => setCoverType('solidColor')}
                value="solidColor"
                name="radio-coverType"
                inputProps={{ 'aria-label': 'A' }}
                sx={{
                  '&.Mui-checked': {
                    color: ccColorTeal,
                  },
                }}
              />
            <div>Solid color</div>
          </div>
          <LionColorPicker 
            color={coverSolidColor}
            onChange={(val) => {setCoverSolidColor(val); doOnChangeWithNewValue('coverSolidColor', val)}}
          />


        </div>
      </div>

      <div className='mt-2'>
        <CcCheckbox displayName="Add effect"
        checked={props.snippetInfo.webPageSettings ? props.snippetInfo.webPageSettings.coverAddEffect : false}
        onChange={(e) => {doOnChangeWithNewValue('coverAddEffect', e.target.checked);}}
        />
        {props.snippetInfo.webPageSettings && props.snippetInfo.webPageSettings.coverAddEffect &&
        
        <SvgSelector ref={svgSelectorRef}
            pointerXml={props.snippetInfo.webPageSettings && props.snippetInfo.webPageSettings.coverAddEffectXml} 
         />}
      </div>
    </div>
    </LocalizationProvider>
  </div>
})

const GoogleFontsSelector = (props) => {
  const [selectedFont, setSelectedFont] = useState('')
  const [foundFonts, setFoundFonts] = useState([])
  const [searchedAndSelectedFonts, setSearchedAndSelectedFonts] = useState([])

  useEffect(() => {
    if (props.fonts === undefined || props.fonts.length === 0) return;

    if (props.selected && props.selected.length > 0) setSelectedFont(props.selected)
    else setSelectedFont(props.fonts[0].id)
  }, [props.selected, props.fonts])

  const handleChangeFont = (fName) => {
    setSelectedFont(fName)
    if (props.onChange) props.onChange(fName)
  }

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const handleClosePreviewDialog = () => {
    setOpenPreviewDialog(false)
  }

  const handleSearchChange = (e) => {
    const word = e.target.value
    if (word.length === 0) {
      setFoundFonts([])
      return;
    }
    const matches = props.fonts.filter(f => f.family.toLowerCase().includes(word)).slice(0, 50);
    setFoundFonts(matches)
  }
  const handleSearchSelectedFont = (fname) => {
    const newSearchedFonts = [...searchedAndSelectedFonts, {id: fname, name: fname, family: fname}]
    setSearchedAndSelectedFonts(newSearchedFonts)
    setSelectedFont(fname)
    if (props.onChange) props.onChange(fname)
    setOpenPreviewDialog(false)

  }

  return  <div className='flex flex-row items-center'>
            <img src='/assets/popularBadge.png' width={64} height={64} alt='' />
            <div className='w-52'>
              <LionListbox 
                items={props.fonts.slice(0, 5).concat(searchedAndSelectedFonts).map(f => {return {id: f.family, name: f.family}})} 
                zIndex={1000}
                selectedItemId={selectedFont} 
                onChange={(item) => handleChangeFont(item.id)}
                />
            </div>
            <div className='ml-1 cursor-pointer' onClick={() => setOpenPreviewDialog(true)}><Iconify icon="material-symbols:search" width={32} /></div>
    
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openPreviewDialog}
        onClose={handleClosePreviewDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='p-4 h-[800px] overflow-auto'>
        <input
            className="block w-full rounded-md border-0 py-8 px-5 text-6xl text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-ccColorDarkTeal placeholder:text-gray-400 outline-none focus:ring-2 focus:ring-inset focus:ring-ccColorTeal"
            onChange={handleSearchChange}
            //onKeyDown={handleSearchChange}
            //value={inputValue}
            placeholder="start typing..."
          />
        <div className='mt-4 text-ccColorDarkTeal flex flex-col gap-y-2'>
          {foundFonts.map(f => <div className='bg-slate-100 p-2 rounded w-96 flex flex-row items-center'>
            <div className='font-bold text-2xl'>{f.family}</div>
            <div className='flex grow'></div>
            <CcActionButton onClick={() => handleSearchSelectedFont(f.family)}>Select</CcActionButton>
            </div>)
          }
        </div>
        </div>
        <DialogActions>
          <CcActionButton className='mr-2 mb-2' onClick={handleClosePreviewDialog}>Close</CcActionButton>
        </DialogActions>
      </Dialog>


  </div>
}

const WebPagePreviewer = (props) => {
  /*
  props:

  onRequestPointerB64: we need to use this to request the pointer image if coverAddEffect = true
  */
  const [fetchingPreview, setFetchingPreview] = useState(false)
  const [previewHtml, setPreviewHtml] = useState('')


  const getWebPagePreview = () => {
    setOpenPreviewDialog(true)
    setFetchingPreview(true)
    getPreviewHtml()
  }

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const handleClosePreviewDialog = () => {
    setOpenPreviewDialog(false)
  }

  const getPreviewHtml = async () => {
    setPreviewHtml('')
    try {
      let url = `${process.env.REACT_APP_LION_API_URL}/WebPages/Preview`;
      const dataToPost = {snippetTypeId: 15, webPageTemplateId: props.snippetInfo.webPageSettings.selectedWebPageId,
                           revealB64: props.snippetInfo.snippetSettings.revealImages[0].imgB64, href: props.snippetInfo.snippetSettings.revealImages[0].href
                          ,propValuesCollection: props.snippetInfo.webPageSettings.templatePropValuesCollection
                          ,coverType: props.snippetInfo.webPageSettings.coverType, coverSolidColor: props.snippetInfo.webPageSettings.coverSolidColor 
                          ,coverB64: props.snippetInfo.snippetSettings.teaserImgB64, coverAddEffect: props.snippetInfo.webPageSettings.coverAddEffect
                          ,expiryDate: props.snippetInfo.webPageSettings.expiryDate, expiryTimezoneId: props.snippetInfo.webPageSettings.expiryTimezoneId }

        
      if (props.snippetInfo.webPageSettings && props.snippetInfo.webPageSettings.coverAddEffect === true) {
        if (props.onRequestPointerB64) {
          const pointer = await props.onRequestPointerB64()
          dataToPost.coverAddEffectB64 = pointer;
        }
      }

      const response = await axios.post(url, dataToPost);
      //setPreviewHtml(response.data)
      
      const blob = new Blob([response.data], { type: 'text/html' });

      // Generate a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Open the Blob URL in a new browser tab
      window.open(blobUrl, '_blank');
      
    } catch (error) {
      console.log(error);
    }
    finally {setFetchingPreview(false); handleClosePreviewDialog();}

  }

  return  <div className='flex justify-center'>
  <CcActionButton className="mt-2" type="button" onClick={getWebPagePreview} >Preview web page</CcActionButton>

  <Dialog
        fullWidth
        maxWidth="lg"
        open={openPreviewDialog}
        onClose={handleClosePreviewDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='p-1 h-[800px] overflow-auto'>
          {fetchingPreview && <div className='flex justify-center mt-48'>
                <CcLoader size={96} />
                </div>
          }
          {!fetchingPreview && <div className=''>
                <iframe className='w-full' srcDoc={previewHtml} sandbox="allow-scripts allow-same-origin" title='Preview'></iframe>
                </div>
          }
          
        </div>
        <DialogActions>
          <CcActionButton className='mr-2 mb-2' onClick={handleClosePreviewDialog}>Close</CcActionButton>
        </DialogActions>
      </Dialog>

</div>

}

export default function ScratchWinWorkflow(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const [templateId, setTemplateId] = useState(null);
    const [templateHasTimeLabels, setTemplateHasTimeLabels] = useState(false); // we check if the selected template contains time labels (by checking its templateJson)
    const [snippetData, setSnippetData] = useState({snippetName: '', snippetDesc: '', 
                  webPageSettings: {coverAddEffect: false, selectedWebPageId: 1, templatePropValuesCollection: [], coverType: 'solidColor', coverSolidColor: '#b3b3b3', expiryDate: dayjs().add(24, 'hour').toJSON(), expiryTimezoneId: 'Europe/London' },
                  snippetSettings: {teaserImgB64: '', revealImages: [{imgB64: '', href:''}], pointerB64: '',
                                      partialRevealSettings: {b64: '', metadata: {}}, emailEffectType: 'coverOnly'}});


    const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [generatingPreview, setGeneratingPreview] = useState(false)
    const [teaserPreview, setTeaserPreview] = useState('')
    const [partialRevealPreviews, setPartialRevealPreviews] = useState([''])

    const [activeErrorList, setActiveErrorList] = useState([]);

    const elementManagerRef = useRef();
    const elementWebPageManagerRef = useRef()

    let params = useParams();
    useEffect(() => {
      if (params.snippetId !== undefined) {
        getSnippetInfo(params.snippetId)
      }
    }, [props.snippetId])

    const getSnippetInfo = async (snippetId) => {
      
      try {  
        const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
        const response = await axios.get(url);
        const data = response.data;

        const newSnippetData = response.data;
        newSnippetData.snippetSettings = JSON.parse(response.data.snippetSettings);
        newSnippetData.webPageSettings = JSON.parse(response.data.webPageSettings);
        console.log(newSnippetData.webPageSettings)
        setSnippetData(newSnippetData)


      } catch (error) {
        console.log(error);
      }
      
    }
    const templateContainsTimeLabels = (templateJson) => {
      let result = false;
      const blocks = templateJson.model.children[0].children;

      blocks.forEach(block => {
        if (block.className === "Text" && block.attrs.id.startsWith('timerLabel')) result = true;
      })
      console.log(result)
      return result;
    }

    const submitDataSnippet = async () => {
      try {
        //console.log(snippetData)
        snippetDataValidationSchemaScratch.validateSync(snippetData, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        //console.log(JSON.parse(JSON.stringify(e)))
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return false;
      }


      setShowLoader(true);
      setSuccessHtmlMsg('');
    
      try {
          let url = `${process.env.REACT_APP_LION_API_URL}/Snippets/create/15`;
          if (params.snippetId !== null && params.snippetId !== undefined) url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/15/${params.snippetId}`;

          
          if (snippetData.webPageSettings.coverAddEffect) {
            const pointerInfo = await elementWebPageManagerRef.current.askPointerImg();
            snippetData.webPageSettings.coverAddEffectB64 = pointerInfo.b64
            snippetData.webPageSettings.coverAddEffectXml = pointerInfo.pointerXml
          }

          const dataToPost = {...snippetData, href: '', snippetSettings: JSON.stringify(snippetData.snippetSettings), 
                              webPageSettings: JSON.stringify(snippetData.webPageSettings)}

          const response = await axios.post(url, dataToPost);
    
          setSuccessHtmlMsg(response.data);
          setShowSuccessMsg(true);
      } catch (error) {
        setShowSuccessMsg(false);
        setShowErrorMsg(true);
        console.log(error);
      }
      setShowLoader(false);
      setSubmitted(true)

      return true;
    }
  const handleElementChange = (snippetInfo) => {
    //console.log(snippetInfo)
    setSnippetData(snippetInfo)

    if (activeErrorList.length > 0) {
      try {
        snippetDataValidationSchemaScratch.validateSync(snippetInfo, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return;
      }  
    }
  }
  const handleElementSnippetSettingsChange = (snippetSettings) => {
    //console.log(snippetSettings)
    const newSettings = {...snippetData, snippetSettings: snippetSettings}
    setSnippetData(newSettings)
    setPartialRevealPreviews(Array(snippetSettings.revealImages.length).fill(''));


    if (activeErrorList.length > 0) {
      try {
        snippetDataValidationSchemaScratch.validateSync(newSettings, {abortEarly: false});
        setActiveErrorList([])
      }
      catch (e) {
        const errorList = getErrorListFromYupError(e)
        setActiveErrorList(errorList)
        return;
      }  
    }
  }
  const handleElementWebPageChange = (settings) => {
    const newSettings = {...snippetData, webPageSettings: settings}
    //console.log(newSettings)
    setSnippetData(newSettings)

  }
  const handleRevealImagesChange = (newRevealImages) => {
    const newSnippetSettings = {...snippetData.snippetSettings, revealImages: newRevealImages}
    const newSettings = {...snippetData, snippetSettings: newSnippetSettings}
    //console.log(newSettings)
    setSnippetData(newSettings)
  }

  const handleNavChange = async(navInfo) => {
    if (navInfo.actionType === 'Navigation'){
      //if we are moving from 0 to 1 we need to apply some validation
      if (currentStep === 0 && navInfo.nextIndex === 1) {
        try {
          //console.log(snippetData)
          snippetDataValidationSchemaScratch.validateSync(snippetData, {abortEarly: false});
          setActiveErrorList([])
        }
        catch (e) {
          console.log(JSON.parse(JSON.stringify(e)))
          const errorList = getErrorListFromYupError(e)
          setActiveErrorList(errorList)
          return false;
        }    
      }
      if ((currentStep === 1 && navInfo.nextIndex === 2) || (currentStep === 1 && navInfo.nextIndex === 0)) {
        if (snippetData.snippetSettings.emailEffectType === 'coverAndTeaser') {
          const pointerInfo = await elementManagerRef.current.askPointerImg()
          const newSnippetSettings = {...snippetData.snippetSettings, pointerB64: pointerInfo.b64, pointerXml: pointerInfo.pointerXml}
          const newSettings = {...snippetData, snippetSettings: newSnippetSettings}
          setSnippetData(newSettings)  
        }
        else if (snippetData.snippetSettings.emailEffectType === 'coverAndPartialReveal') {
          const brushProps = await elementManagerRef.current.askBrushB64()
          const newSnippetSettings = {...snippetData.snippetSettings, partialRevealSettings: brushProps}
          const newSettings = {...snippetData, snippetSettings: newSnippetSettings}
          setSnippetData(newSettings)  
          console.log(newSettings)
  
        }
      }
      setCurrentStep(navInfo.nextIndex);
    }
    else if (navInfo.actionType === 'Submit') {
          const valid = await submitDataSnippet()
          if (valid) setCurrentStep(navInfo.nextIndex);
    }
  }
    
    const handleSaveExitClicked = () => {
        navigate("/ph/scratch")
    }
    const handleNextClicked = (tId, tJson) => {
        setTemplateId(tId)
        setTemplateHasTimeLabels(templateContainsTimeLabels(tJson));
        setCurrentStep(2);
    }
    const handleClose = () => {
        navigate("/ph/scratch")
    }
    
    const getTeaserAnimation = async () => {
      try {
        setGeneratingPreview(true)
        setOpenPreviewTeaser(true)
        setTeaserPreview('')
        const pointerInfo = await elementManagerRef.current.askPointerImg()
        const pointerPng = pointerInfo.b64
        console.log(pointerInfo)

  
        let url = `${process.env.REACT_APP_LION_API_URL}/Animations/scratch/getTeaser`;
        const dataToPost = {mainImg: snippetData.snippetSettings.teaserImgB64, pointerImg: pointerPng}
  
        const response = await axios.post(url, dataToPost);
  
        setTeaserPreview(response.data)
  
    } catch (error) {
      console.log(error)
    }
    finally {setGeneratingPreview(false)}
  
    }
    const getPartialRevealAnimation = async () => {
      try {
        setGeneratingPreview(true)
        setOpenPreviewTeaser(true)
        const brushProps = await elementManagerRef.current.askBrushB64()
        const brush = brushProps.b64;
  
        let url = `${process.env.REACT_APP_LION_API_URL}/Animations/scratch/getPartialReveal`;

        const requests = partialRevealPreviews.map((_, index) => {
          const dataToPost = {coverImg: snippetData.snippetSettings.teaserImgB64, revealImg: snippetData.snippetSettings.revealImages[index].imgB64, brushImg: brush}
          return axios.post(url, dataToPost)
            .then((response) => ({ index, data: response.data }))
            .catch(() => ({ index, data: '' }))
        });

        

        requests.forEach(async (request) => {
          try {
            const { index, data } = await request;
            setPartialRevealPreviews((prevImages) => {
              const updatedImages = [...prevImages];
              updatedImages[index] = data;
              //console.log(updatedImages);
              return updatedImages;
            });
          } catch (error) {
            //console.error(`Error:`, error);
          }
        });

        Promise.all(requests)
        .then(results => {
          setGeneratingPreview(false)
        })
  
    } catch (error) {
      console.log(error)
    }
    //finally {setGeneratingPreview(false)}
  
    }
    const getPreviewCover = async () => {
      setTeaserPreview('')
      //setPartialRevealPreviews(Array(snippetData.snippetSettings.revealImages.length).fill(''));
      switch (snippetData.snippetSettings.emailEffectType)
      {
        case "coverOnly":
          setGeneratingPreview(true)
          setOpenPreviewTeaser(true)
  
        break;
        case "coverAndTeaser":
          getTeaserAnimation()
        break;
        case "coverAndPartialReveal":
          getPartialRevealAnimation()
        break;
        default:
        break
      }
    }

    const [openPreviewTeaser, setOpenPreviewTeaser] = useState(false);
    const handleClosePreviewTeaser = () => {
      setOpenPreviewTeaser(false)
      setPartialRevealPreviews(partialRevealPreviews.fill(''))
      setTeaserPreview('')
    }

    const handleRequestPointerB64 = async () => {
      const result = await elementWebPageManagerRef.current.askPointerImg();

      return result.b64
    }

    const arrowStyles = {
      position: 'absolute',
      zIndex: 2,
      //top: 'calc(50% - 15px)',
      width: 40,
      //height: 30,
      top: 0,
      height: '100%',
      cursor: 'pointer',
  };

    return <div>
        <CcStepper2
            steps={[{ name: 'Assets', href: '#' },
                    { name: 'Cover', href: '#' },
                    { name: 'Reveal', href: '#' },
                    { name: 'Get Element', href: '#' },
                ]}
            currentStepIndex={currentStep}
        />
        <div className="border border-2 border-gray-200 rounded-md p-2">

            {currentStep === 0 && <ScratchWinElementAssetsManager
                                    onChange={handleElementChange}
                                    validationErrorsList={activeErrorList}
                                    snippetInfo={snippetData}
              />}
            {currentStep === 1 && <><ScratchWinElementEmailManager
                                    onChange={handleElementSnippetSettingsChange}
                                    validationErrorsList={activeErrorList}
                                    snippetInfo={snippetData}
                                    ref={elementManagerRef}
              />
              <div className='flex justify-center'>
              <CcActionButton className="mt-2" type="button" onClick={getPreviewCover} >Preview cover</CcActionButton>
            </div>
            </>
            }
            {currentStep === 2 && <>
              <ScratchWinElementWebPageManager
                                    onChange={handleElementWebPageChange}
                                    onChangeRevealImages={handleRevealImagesChange}
                                    snippetInfo={snippetData}
                                    validationErrorsList={activeErrorList}
                                    ref={elementWebPageManagerRef}
              />
              <WebPagePreviewer 
                                    snippetInfo={snippetData}
                                    onRequestPointerB64={handleRequestPointerB64}
              />
            </>

            }

            {currentStep === 3 && <div className='flex flex-col items-center'>
                {!showLoader && !showSuccessMsg && !showErrorMsg && <div>Confirm</div> }
                {showLoader && <LionLoaderGradient /> }
                {showSuccessMsg && <SnippetCodeViewer forScrachOff numSnippets={snippetData.snippetSettings.revealImages.length} html={successHtmlMsg} />}
                {(showSuccessMsg || showErrorMsg) && <div> <CcActionButton variant="contained" onClick={handleClose} >Close</CcActionButton></div>}
            </div>}

            {currentStep !== 3 &&
                    <StepperNavButtons totalSteps={4}
                    currentStepNum={currentStep}
                    onChange={handleNavChange}
                    showClose={submitted}
                    onClose={handleClose}
                />
            }
        </div>

        <Dialog
        fullWidth
        maxWidth="lg"
        open={openPreviewTeaser}
        onClose={handleClosePreviewTeaser}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='flex items-end'>
          <div className='flex grow'></div>
          <IconButton sx={{m:1}} onClick={handleClosePreviewTeaser}><Iconify icon={lionIcons.Icon_Actions_Cancel} width={40} /></IconButton>
        </div>

        <div className='flex flex-col items-center justify-center text-ccColorDarkTeal font-poppins'>
          <div className=' text-3xl  font-bold m-3'>{"Preview"}</div>
            <div className='w-[800px] h-2'></div>
            {snippetData.snippetSettings.emailEffectType === 'coverOnly' && <div className='w-[800px] h-[650px]'>
              <div className='relative h-full' >
              <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`${snippetData.snippetSettings.teaserImgB64}`} alt='Preview' />   
              </div>        
            </div>
            }
            {snippetData.snippetSettings.emailEffectType === 'coverAndTeaser' && <>
              {generatingPreview &&  
                    <div className=''><CcLoader size={128} /></div>
              }
              {teaserPreview.length > 0 && <div className='w-[800px] h-[650px]'>
                  <div className='relative h-full' >
                    <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`data:image;base64,${teaserPreview}`} alt='' />
                  </div>
                </div>
              }            
            </>
            }
            {snippetData.snippetSettings.emailEffectType === 'coverAndPartialReveal' && <>
              {partialRevealPreviews.length === 1 && <div className='w-[500px]'>
                {generatingPreview &&  
                    <div className='flex justify-center'><CcLoader size={128} /></div>
                }
                {partialRevealPreviews[0].length > 0 && <div className='w-[800px] h-[650px]'>
                    <div className='relative h-full' >
                      <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`data:image;base64,${partialRevealPreviews[0]}`} alt='Preview' />
                    </div>
                  </div>
                }
                </div>
              }
              {partialRevealPreviews.length > 1 && <div>

                <Carousel width={700} showStatus={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <div className='flex justify-center items-center text-ccColorTeal bg-slate-700 bg-opacity-30 rounded-s hover:bg-opacity-70 hover:text-ccColorLightTeal' onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 0 }}>
                          <Iconify flip="horizontal" color='white' icon="material-symbols:play-arrow-rounded" width={32} />
                        </div>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <div className='flex justify-center items-center text-ccColorTeal bg-slate-700 bg-opacity-30 rounded-s hover:bg-opacity-70 hover:text-ccColorLightTeal' onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 0 }}>
                            <Iconify icon="material-symbols:play-arrow-rounded" width={32} />
                        </div>
                    )
                  }                
                >
                  {partialRevealPreviews.map(p => {
                    if (p === '') return <div className='w-[700px] h-[300px] flex justify-center items-center'>
                                            <CcLoader />
                                        </div> 
                    else return <div className='relative w-[700px] flex justify-center'>
                                    <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={`data:image;base64,${p}`} alt='' />
                                    {/*<p className="legend">Legend 1</p>*/}
                                </div>
                  })

                  }
            </Carousel>
              
              </div>
              }
            </>
            }
        </div>
{/*
        <DialogActions>
          <CcActionButton className='mr-2 mb-2' onClick={handleClosePreviewTeaser}>Close</CcActionButton>
        </DialogActions>

*/}
      </Dialog>

    </div>
}