import { deepOrange, red, pink, indigo, green, blue, teal, amber, yellow, lightGreen, cyan, deepPurple, purple, orange, lime } from '@mui/material/colors';


//style={{background: "repeating-conic-gradient(#e0e0e0 0% 25%, #f8f8f8 25% 50%) 50% / 10px 10px" }}

const lionObjectsColors = {
    Color_WebResource: pink[500],
    Color_WebScrape: indigo[500],
    Color_Catalog: green[500],
    Color_FormatCSV: blue[500],
    Color_FormatXML: teal[500],
    Color_FormatJSON: pink[500],
    Color_Databind_none: pink[500],
    Color_Databind_url: indigo[500],
    Color_Databind_databound: green[500],
    Color_Databind_imgLibrary: amber[500],
    Color_SnippetLabel: '#17425d',
}

const lionProductColors = {
    DataVisual: deepOrange[400],
    ProgressBar: amber[400],
    IconProgressBar: yellow[400],
    ScatchOff: lightGreen[500],
    Vote: teal[400],
    EngagementCounter: cyan[400],
    Sentiment: deepPurple[400],
    Barcode: purple[400],
    Generator: orange[400],
    Instagram: pink[400],
    Twitter: indigo[400],
    Timer: lime[400],
}

const lionGradient1 = ['#5186A3', '#8ED2D7', '#A8DDA1']
const lionGradient2 = ['#453471', '#3D79BD', '#6EB3E3']
const lionGradient3 = ['#9240BE', '#8AA9F3', '#57E1DF']

const ccColorDarkTeal = '#17425d'
const ccColorTeal = '#357e86'
const ccColorLightTeal = '#9bcccd'
const ccColorOrange = '#ea8c44'
const ccColorYellow = '#fbbc3d'
const ccColorSecondaryPink = '#ff8095'
const ccColorSecondaryGreen = '#55a68c'
const ccColorSecondaryPurple = '#a274a3'
const ccColorSecondaryAzure = '#6dbfe8'
const ccColorSecondaryBronze = '#e6b543'

const linkStyle = {
    textDecoration: "none",
    color: 'inherit'
    };

export { lionObjectsColors, lionProductColors, lionGradient1, lionGradient2, lionGradient3, linkStyle,
    ccColorDarkTeal, ccColorTeal, ccColorLightTeal, ccColorOrange, ccColorYellow, 
    ccColorSecondaryPink, ccColorSecondaryGreen, ccColorSecondaryPurple, ccColorSecondaryAzure, ccColorSecondaryBronze
}
