import { useNavigate } from 'react-router-dom';

import {CcStepper2, StepperNavButtons} from '../commonComponents/stepper'
import {LionLoaderGradient} from '../commonComponents/generalComponents'
import { Icon as Iconify } from '@iconify/react';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import {SnippetCodeViewer} from '../commonComponents/snippetGeneric'


import { useParams } from "react-router-dom";
import axios from 'axios';

import ScratchWinWorkflow from '../workflows/scratchWinWorkflow';

import { getErrorListFromYupError, getErrorMessage, snippetDataValidationSchemaOSTracker } from '../components/helperErrors'

// ----------------------------------------------------------------------


export default function ScratchWinWizard(props) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [snippetData, setSnippetData] = useState(undefined)


  const [successHtmlMsg, setSuccessHtmlMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  let params = useParams();
  const navigate = useNavigate();

  const [activeErrorList, setActiveErrorList] = useState([]);


  useEffect(() => {
      //console.log(params)
    if (params.snippetId !== undefined) {
      getSnippetInfo(params.snippetId)
    }
  }, [params])

  const getSnippetInfo = async (snippetId) => {
    try {  
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/${snippetId}`;
      const response = await axios.get(url);
      const data = response.data;

        const dataObj = {...data, snippetSettings: JSON.parse(data.snippetSettings)}
      setSnippetData(dataObj);

    } catch (error) {
      console.log(error);
    }
  }

  const handleNavChange = async(navInfo) => {
    if (navInfo.actionType === 'Navigation'){
      if (navInfo.nextIndex === 1) {
      }
      setCurrentStepIndex(navInfo.nextIndex);
    }
    else if (navInfo.actionType === 'Submit') {
      const valid = await submitData();
      if (valid) setCurrentStepIndex(navInfo.nextIndex);
    }
  }

  const submitData = async () => {
    try {
      //console.log(snippetData)
      snippetDataValidationSchemaOSTracker.validateSync(snippetData, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      console.log(JSON.parse(JSON.stringify(e)))
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
      return false;
    }

    setShowLoader(true);
    setSuccessHtmlMsg('');
  
    try {
        //it can only be an update
        const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/update/13/${params.snippetId}`;
    
        const dataToPost = {...snippetData, snippetSettings: JSON.stringify(snippetData.snippetSettings)}

        const response = await axios.post(url, dataToPost);
  
        setSuccessHtmlMsg(response.data);
        setShowSuccessMsg(true);
    } catch (error) {
      setShowSuccessMsg(false);
      setShowErrorMsg(true);
      console.log(error);
    }
    setShowLoader(false);
    setSubmitted(true);
    return true;
  }


  const handleElementChange = (snippetInfo) => {
    setSnippetData(snippetInfo)
    try {
        snippetDataValidationSchemaOSTracker.validateSync(snippetInfo, {abortEarly: false});
      setActiveErrorList([])
    }
    catch (e) {
      const errorList = getErrorListFromYupError(e)
      setActiveErrorList(errorList)
      //return false;
    }

  }
  const handleClose = () => {
    navigate("/ph/scratch")
  }

 return (
  <>
        <ScratchWinWorkflow />

  </>
);
}
