// each page maintain an active error list (which come from yup)
// containg the error message and the component name the error refers to

import { object as yupObject, string, number, date, InferType, addMethod } from 'yup';

export function getErrorListFromYupError(yupError) {
    const errors = JSON.parse(JSON.stringify(yupError))
    //console.log(errors)
    return errors.inner.map(error => {
                             return {fieldName: error.path, message: error.message, params: error.params}                            
    })

}

export function getErrorMessage(errorList, fieldName) {
    if (errorList === undefined) return undefined;
    
    const matched = errorList.find(e => e.fieldName === fieldName)

    if (matched) return matched.message
    else return undefined
}

export function getErrorMessageForArray(errorList, fieldName, index) {
  if (errorList === undefined) return undefined;
  
  const matched = errorList.find(e => e.fieldName === fieldName)
  if (matched) {
    console.log(matched.params.indexes.includes(index))
    if (matched.params.indexes.includes(index)) return matched.message
    else return undefined
  }

  return undefined
}


export const snippetDataTimerValidationSchema = yupObject({
    snippetName: string().required("Required field"),
    snippetDesc: string().required("Required field"),
    href: string().required("Required field").url("Must be a valid url"),
    
    timerSettings: string().test(
      'validateInputDateFormat',
      '',
      (value, context) => {
        const valueJson = JSON.parse(value)
        if (!valueJson.timerType.startsWith('custom')) return true;

        if (valueJson.inputDateFormat === undefined || valueJson.inputDateFormat === '')
          return context.createError({path: 'timerSettingsInputDateFormat', message: 'Required field'});
        else return true;
      }
    ).test(
      'validateHrefAfterExpiry',
      '',
      (value, context) => {
        const valueJson = JSON.parse(value)
        const urlValidator = string().url();
        const isValidUrl = urlValidator.isValidSync(valueJson.hrefAfterExpiry)
        if (isValidUrl) return true;
        else return context.createError({path: 'timerSettingsHrefAfterExpiry', message: 'Must be a valid url'});

      }
    )
    
    /*timerSettings: yupObject({
      inputDateFormat: string().required("Required field"),
    })*/
  });

export const snippetDataValidationSchemaBase = yupObject({
    snippetName: string().required("Required field"),
    snippetDesc: string().required("Required field"),
    href: string().required("Required field").url("Must be a valid url"),
  });

  export const snippetDataValidationSchemaGenerator = yupObject({
    snippetName: string().required("Required field"),
    snippetDesc: string().required("Required field"),
    href: string().required("Required field"),
  });

  export const snippetDataValidationSchemaOSTracker = yupObject({
    snippetName: string().required("Required field"),
    snippetDesc: string().required("Required field"),

    snippetSettings: yupObject().test(
      'validateDefaultHref',
      '',
      (value, context) => {
        //console.log(value)
        if (value.Other.href && value.Other.href.length > 0) return true;
        else return context.createError({path: 'snippetSettingsDefaultHref', message: 'Required field'});

      }
    ).test(
      'validateDefaultImg',
      '',
      (value, context) => {
        //console.log(value)
        if (value.Other.imgB64 && value.Other.imgB64.length > 0) return true;
        else return context.createError({path: 'snippetSettingsDefaultImg', message: 'Required'});

      }
    )
  });

  export const snippetDataValidationSchemaScratch = yupObject({
    snippetName: string().required("Required field"),
    snippetDesc: string().required("Required field"),
    snippetSettings: yupObject().test(
      'coverImgUploaded',
      '',
      (value, context) => {
        //console.log(value)
        if (value.teaserImgB64.length > 0) return true;
        else return context.createError({path: 'snippetSettingsCoverImg', message: 'You must upload a cover image'});

      }
    ).test(
      'revealImgUploaded',
      '',
      (value, context) => {
        //console.log(value)
        const errors = []
        value.revealImages.forEach((img, index) => {if (img.imgB64.length === 0) errors.push(index)})
        if (errors.length > 0) return context.createError({path: 'snippetSettingsRevealImg', message: 'You must upload a reveal image', params: {indexes: errors}});
        else return true;
      }
    ).test(
      'revealHref',
      '',
      (value, context) => {
        //console.log(value)
        const errors = []
        value.revealImages.forEach((img, index) => {if (img.href.length === 0) errors.push(index)})
        if (errors.length > 0) return context.createError({path: 'snippetSettingsRevealHref', message: 'Href is required', params: {indexes: errors}});
        else return true;
      }
    )

  });
