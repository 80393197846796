import { DataGridPro, useGridApiRef, useGridApiContext, GridActionsCellItem } from '@mui/x-data-grid-pro';
import {Alert, Button, Chip, Popper, Box, Typography,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  CircularProgress, FormControl, FormControlLabel, IconButton, Paper, Popover,
  Radio, RadioGroup,
   Slide, Snackbar, Tooltip, Stack} from '@mui/material';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import axios from 'axios';

import { useState, useEffect, useCallback, forwardRef } from 'react';
import { Theme, useTheme } from '@mui/material/styles';

// import Paper from 'src/theme/overrides/Paper';
import { Icon as Iconify } from '@iconify/react';


import {TransformDataForGrid}  from './helpers';
import {lionIcons, svgElementIcons}  from './helpersIcons';
import {ccColorDarkTeal, ccColorSecondaryPink, lionObjectsColors}  from './helpersStyles';
import {store} from '../redux/store'
import { setLabels } from '../redux/slices/userSettings';
import { LabelManager } from './labelManager';

import {CcActionButton}  from '../commonComponents/styledTexts';
import {HtmlViewerDialog} from './snippetHtmlViewer'
import { CCCustomFooter } from './helperDataGrid';

const Transition = forwardRef((props, ref) => 
  (<Slide direction="up" ref={ref} {...props} />)
);

function RefreshDialog(props) {
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  // const [showClose, setShowClose] = useState(false)
  const [voteResetStats, setVoteResetStats] = useState(0)
  const [fetchingVoteTemplateInfo, setFetchingVoteTemplateInfo] = useState(false)
  const [voteNumQuestionsChanged, setVoteNumQuestionsChanged] = useState(false)

  useEffect(() => {
    if (open) {
      if (props.snippetTypeId === 5 || props.snippetTypeId === 6 || props.snippetTypeId === 7) {
        setFetchingVoteTemplateInfo(true);
        checkNumQuestionsHasChanged(props.snippetId)
      }
      else setFetchingVoteTemplateInfo(false);  
    }
  }, [open])

  const handleClickOpen = () => {
    setOpen(true);
    if (props.onClick) props.onClick();
  };

  const refreshSnippet = async (snippetId) => {
    setShowLoader(true)
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/refresh/${snippetId}?snippetTypeId=${props.snippetTypeId}&voteReset=${voteResetStats}`;
      const response = await axios.get(url);
      // const url = `api/Snippets/refresh/${snippetId}?snippetTypeId=${props.snippetTypeId}&voteReset=${voteResetStats}`;
      // const response = await fetch(url, { method: "GET", });
      // response.data = await response.json();

      setShowLoader(false)
      if (response.data.success) setShowSuccess(true)
      else setErrorMsg(response.data.errorMsg)
    } catch (error) {
      setErrorMsg(error);
    }
    finally {
      setShowLoader(false)
    }

  
  }
  const checkNumQuestionsHasChanged = async (snippetId) => {
    try {
        const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/votes/numQuestionsHasChanged/${snippetId}`;
        let response = await axios.get(url);

        //console.log(response.data)
        setVoteNumQuestionsChanged(response.data.numQuestionsChanged);

                
    } catch (error) {
        console.log(error);
    }
    finally{
      setFetchingVoteTemplateInfo(false)
    }
}


  const handleClose = (submit) => {
    if (submit) {
      refreshSnippet(props.snippetId)
    }
    else setOpen(false);
  };
  const handleCloseAndClearState = () => {
    setShowLoader(false);
    setShowSuccess(false);
    setErrorMsg('');
    setVoteResetStats(0);

    setOpen(false);
  }


  return (<div>
      <Iconify icon='material-symbols:refresh'
              onClick={handleClickOpen} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAndClearState}
        aria-describedby="alert-dialog-slide-description"
      >
        {!fetchingVoteTemplateInfo && <DialogTitle><div className='text-ccColorDarkTeal'>{"Are you sure you’d like to update this element with the latest template?"}</div></DialogTitle>}

        <DialogContent>
        {fetchingVoteTemplateInfo && <>
                <DialogTitle>{"Fetching template info..."}</DialogTitle>
                <Box  sx={{pb:1, display: 'flex', justifyContent: 'center'}}  >
                <CircularProgress sx={{marginTop: '15px'}} size={64} />
                </Box>
              </>
        }
        {showLoader && !showSuccess && errorMsg.length === 0 &&
              <Box  sx={{pb:1, display: 'flex', justifyContent: 'center'}}  >
              <CircularProgress sx={{marginTop: '50px'}} size={64} />
              </Box>
        }
        {!fetchingVoteTemplateInfo && !voteNumQuestionsChanged && !showLoader && !showSuccess && errorMsg.length === 0 &&
              <DialogContentText id="alert-dialog-slide-description">
                Clicking ‘Yes’ will update this element’s template to the latest version. This cannot be undone.
                  {(props.snippetTypeId === 5 || props.snippetTypeId === 6 || props.snippetTypeId === 7) && <Stack mt={2}>
            <Typography>Would you like to reset all votes too?</Typography> 
            <FormControl>
              <RadioGroup row
                name="radio-reset-group"
                value={voteResetStats}
                onChange={(e) => setVoteResetStats(parseInt(e.target.value, 10))}
              >
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
                </Stack>
                }
            </DialogContentText>
        }
        {voteNumQuestionsChanged && 
              <DialogContentText id="alert-dialog-slide-success">
                <Alert severity="warning">
                  <div className="flex flex-col gap-2">
                    <span className="font-bold">Refresh not allowed</span>
                    <div>The number of questions in the current Element is different than the number of questions in the template. We prevent this change since it could create design issues in live email campaigns</div>
                  </div>
                </Alert>
              </DialogContentText>
        }

        {showSuccess && 
              <DialogContentText id="alert-dialog-slide-success">
                <Alert severity="success">Element has been refreshed</Alert>
            </DialogContentText>
        }
        {errorMsg.length > 0 && 
              <DialogContentText id="alert-dialog-slide-success">
                <Alert severity="error">{errorMsg}</Alert>
            </DialogContentText>
        }
        </DialogContent>
        {(showSuccess || errorMsg.length > 0) &&
          <DialogActions>
            <Button sx={{color: ccColorSecondaryPink}} onClick={handleCloseAndClearState}>Close</Button>
          </DialogActions>
        }
        {!(showSuccess || errorMsg.length > 0) && !fetchingVoteTemplateInfo && !voteNumQuestionsChanged &&
        <DialogActions>
          <CcActionButton onClick={(e) => handleClose(false)}>Cancel</CcActionButton>
          <CcActionButton onClick={() => handleClose(true)}>OK</CcActionButton>
        </DialogActions>
        }
        {voteNumQuestionsChanged &&
        <DialogActions>
          <Button sx={{color: ccColorSecondaryPink}} onClick={() => handleClose(false)}>Close</Button>
        </DialogActions>
        }
      </Dialog>
    </div>
  
    );
}


export default function SnippetsGrid(props) {

  /*
  props:
  productTypeId
  archive: when true it only shows archived items
  */

  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupValue, setPopupValue] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({action: '', id:'', title:'', desc:''});
  const [snackBarClipboardOpen, setSnackBarClipboardOpen] = useState(false);
  const [popupLabelManagerOpen, setPopupLabelManagerOpen] = useState(false);
  const [popupLabelManagerValue, setPopupLabelManagerValue] = useState(false);
  const [popupLabelManagerAnchorEl, setPopupLabelManagerAnchorEl] = useState(null);
  const [tmpLabels, setTmpLabels] = useState([]);
  const [tmpSnippetId, setTmpSnippetId] = useState(0);
  const apiRef = useGridApiRef();

  const [showToolTip, setShowToolTip] = useState(0);
  const [showToolTipHtmlViewer, setShowToolTipHtmlViewer] = useState(0);

const getData = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_LION_API_URL}/Snippets`;
      if (props.productTypeId !== undefined && props.productTypeId !== null) url += `/productType/${props.productTypeId}`
      if (props.archive) url += `/archived`
      const response = await axios.get(url);

      // const url = `${process.env.NEXT_PUBLIC_NEXT_API_URL}/Snippets`;
      // const response = await fetch(url, { method: "GET", });
      // response.data = await response.json();

      setData(TransformDataForGrid(response.data, 'snippetId'));

    } catch (error) {
      console.log(error);
    }


}
  ,[])

  useEffect(() => {getData()}, [getData]);

  const saveLabels = async () => {
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/User/Labels/update?snippetId=${tmpSnippetId}`;
      const response = await axios.post(url, tmpLabels);

      // const url = `api/User/Labels/update?snippetId=${tmpSnippetId}`;
      // const response = await fetch(url, { method: "POST", body: JSON.stringify(tmpLabels) });    
      // response.data = await response.json();

      if (response.data.length > 0) {
        const allLabels = await store.getState().userSettings.labels;
        const newAllLabels = [...allLabels];

        // set tmpLabels with real newIds
        response.data.forEach(l => {
          const newLabel = tmpLabels.find(tL => tL.labelId === l.oldId);
          newLabel.labelId = l.newId;
          newAllLabels.push(newLabel);
        })
        // we update the store with the new labels
        store.dispatch(setLabels(newAllLabels));
      }
      apiRef.current.updateRows([{ id: tmpSnippetId, labels: tmpLabels }]);



    } catch (error) {
      console.log(error);
    }
  }


  
const editSnippet = (id, typeId) => {
  // console.log(typeId)
  // MOD: we go to a new workflow page to manage the edit
  switch(typeId) {
    case 1:
      window.location.href = `/generator/${id}`
      break;
    case 5:
      window.location.href = `/votes/${id}`
      break;
    case 6:
      window.location.href = `/clickcounter/${id}`
      break;
    case 7:
      window.location.href = `/moodmeter/${id}`
      break;
    case 9:
      window.location.href = `/textImages/${id}`
      break;
    case 12:
      window.location.href = `/timers/${id}`
      break;
    case 13:
      window.location.href = `/appMatch/${id}`
      break;
    case 14:
      window.location.href = `/persAnimations/${id}`
      break;
    case 15:
      window.location.href = `/scratch/${id}`
      break;
      default:
      break;
  }

  // Unbcomment the line below to work with the old wizard
  // props.editCall(id, typeId);
}
const deleteSnippet = (snippetId) => {
  // we open the confirmation dialog
  const dialogNewData = {action: 'delete', id:snippetId, title:'Delete Snippet', desc:'Are you sure you want to delete this snippet?'};
  setDialogData(dialogNewData);
  setDialogOpen(true);
}
const archiveSnippet = (snippetId) => {
  // we open the confirmation dialog
  const dialogNewData = {action: 'archive', id:snippetId, title:'Archive Element', desc:'Are you sure you want to archive this element?', subDesc: "By confirming you want to archive the element, your element will be archived and it won't be visible in the UI. You can undo this action in the archive area in settings."};
  setDialogData(dialogNewData);
  setDialogOpen(true);
}

const copyHtmlCodeToClipboard = (code) => {
setSnackBarClipboardOpen(true);
navigator.clipboard.writeText(code);
}
const handleCloseSnackBarClipboard = () => {
    setSnackBarClipboardOpen(false);
}
const manageLabels = (event, id, labels) => {
    setPopupLabelManagerOpen(true);
    setPopupLabelManagerValue({snippetId: id, data: labels})
    setPopupLabelManagerAnchorEl(event.currentTarget);
    setTmpSnippetId(id);
}

const SnackBarAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBarClipboard}
      >
        <Iconify icon={lionIcons.Icon_Actions_Cancel} />
      </IconButton>
    </>
  );

  let columnActions = {
    field: 'actions',
    type: 'actions',
    width: 180,  

    getActions: (params) => {const actions = [
        <Tooltip title='Refreshes an element with the latest template edits' placement='top' open={showToolTip === params.id} disableHoverListener onMouseEnter={() => setShowToolTip(params.id)} onMouseLeave={() => setShowToolTip(0)}><GridActionsCellItem icon={<RefreshDialog snippetId={params.id} snippetTypeId={params.row.snippetTypeId} onClick={() => setShowToolTip(0)} />} /></Tooltip>,
        //<Tooltip title='Copy HTML to clipboard' placement='top'><GridActionsCellItem icon={<Iconify icon='heroicons-outline:clipboard-copy' width={20} />} onClick={() => (copyHtmlCodeToClipboard(params.row.htmlCode))}  /></Tooltip>,
        <Tooltip title='Copy element HTML' placement='top' open={showToolTipHtmlViewer === params.id} disableHoverListener onMouseEnter={() => setShowToolTipHtmlViewer(params.id)} onMouseLeave={() => setShowToolTipHtmlViewer(0)}><GridActionsCellItem icon={<HtmlViewerDialog html={params.row.htmlCode} onClick={() => setShowToolTipHtmlViewer(0)} />} /></Tooltip>,
        <Tooltip title='Manage labels' placement='top'><GridActionsCellItem icon={<Iconify icon={lionIcons.Icon_Label} width={20} />} onClick={(event) => (manageLabels(event, params.id, params.row.labels))}  /></Tooltip>,
        <Tooltip title='Archive' placement='top'><GridActionsCellItem icon={<Iconify icon={lionIcons.Icon_Actions_Archive} width={20} />} onClick={() => archiveSnippet(params.id)} label="Delete"  /></Tooltip>,
      ]
      if (props.noEdit === undefined || props.noEdit === false) actions.unshift(<GridActionsCellItem icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill='#0000008a' d="M3 21v-4.25L16.2 3.575q.3-.275.663-.425t.762-.15t.775.15t.65.45L20.425 5q.3.275.438.65T21 6.4q0 .4-.137.763t-.438.662L7.25 21zM17.6 7.8L19 6.4L17.6 5l-1.4 1.4z"/></svg>} onClick={() => (editSnippet(params.id, params.row.snippetTypeId))} label="Edit" disabled={params.row.status === 'PENDING CHANGES'} />)
      return actions
    }

  }
  if (props.archive) {
    columnActions = {
      field: 'actions',
      type: 'actions',
      width: 180,
      getActions: (params) => [
        <Tooltip title='Unarchive' placement='top'><GridActionsCellItem icon={<Iconify icon={lionIcons.Icon_Actions_UnArchive} width={20} />} onClick={() => unArchiveSnippetCall(params.id)} label="Unarchive"  /></Tooltip>,
        <Tooltip title='Delete' placement='top'><GridActionsCellItem icon={<Iconify icon={lionIcons.Icon_Actions_Delete} width={20} />} onClick={() => deleteSnippet(params.id)} label="Delete"  /></Tooltip>,
      ]
    }
  }

  const columns = [
    { field: 'id', headerName: 'Element ID', width: 100 },
    { field: 'snippetTypeId', headerName: 'Type', width: 100,
    renderCell: (params) => {
        if (params.value == null) return <></>;
        if (params.value === 1) return <Tooltip title="Generator" placement='right'><Iconify icon='carbon:ibm-cloud-pak-business-automation' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        if (params.value === 5) return <Tooltip title="Vote" placement='right'><Iconify icon='mdi:vote' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        if (params.value === 6) return <Tooltip title="Click Counter" placement='right'><Iconify icon='tdesign:gesture-click' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        if (params.value === 7) return <Tooltip title="Mood Meter" placement='right'><Iconify icon='fa:smile-o' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        if (params.value === 9) return <Tooltip title="Data Visual" placement='right'><Iconify icon='bi:images' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        if (params.value === 12) return <Tooltip title="Timer" placement='right'><Iconify icon='ph:timer' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        if (params.value === 13) return <Tooltip title="App Match" placement='right'><Iconify icon='fluent:app-unspan-28-filled' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        if (params.value === 14) return <Tooltip title="Personalised Image" placement='right'><Iconify icon='mdi:animation-play-outline' width={38} className='text-ccColorDarkTeal' /></Tooltip>
        return <></>
      }
    },                            
    { field: 'snippetName', headerName: 'Name', width: 250 },
    { field: 'snippetDesc', headerName: 'Description', width: 250 },
    { field: 'created', headerName: 'Created', width: 160, 
      valueFormatter: (params) => {
        if (params.value == null) return '';
        return new Date(Date.parse(params.value)).toLocaleDateString();
      } },
      { field: 'labels', headerName: 'Labels', width: 240,
        renderCell: (params) => {
            if (params.value.length === 0) return ''
            return <Stack direction='row' spacing={1}>
                    {params.value.map(l => <Tooltip key={l.labelId} title={l.labelName}><Chip  label={l.labelName} size='small'
                                            sx={{color: 'white', width:70, backgroundColor: lionObjectsColors.Color_SnippetLabel}}/></Tooltip>)}
            </Stack> 
        }
    },
    
  ];
  columns.push(columnActions);

  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = data.find(r => (r.id.toString() === id));

    // Normal case
    setPopupOpen(isOverflown(event.currentTarget.firstChild));

    setPopupValue(row[field]);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {setPopupOpen(false)};
  const handlePopupLabelManagerClose = (saveChanges = false) => {
    setPopupLabelManagerOpen(false)
    setPopupLabelManagerAnchorEl(null)
    console.log(saveChanges)
    if (saveChanges === true) {
      saveLabels()
      console.log('save changes')
  
    }
    else {
      setTmpLabels([])
      setTmpSnippetId(0)
    }
  };

  
  function isOverflown(element) {
    try {
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );  
    }
    catch (e) {
      return false;
    }
  }
      
  const deleteSnippetCall = async (snippetId) => {
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/delete/${snippetId}`;
      const response = await axios.get(url);
      // const url = `api/Snippets/delete/${snippetId}`;
      // const response = await fetch(url, { method: "GET", });
      // response.data = await response.json();
        
    } catch (error) {
      console.log(error);
    }

  }
  const archiveSnippetCall = async (snippetId) => {
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/archive/${snippetId}`;
      const response = await axios.get(url);
      // const url = `api/Snippets/delete/${snippetId}`;
      // const response = await fetch(url, { method: "GET", });
      // response.data = await response.json();
        
    } catch (error) {
      console.log(error);
    }

  }      
  const unArchiveSnippetCall = async (snippetId) => {
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/Snippets/unarchive/${snippetId}`;
      const response = await axios.get(url);
        
      getData();

    } catch (error) {
      console.log(error);
    }

  }
  const handleDialogClose = async(action) => {
    if (action === 'delete') {
        await deleteSnippetCall(dialogData.id);

      // re-download data to refresh the grid
      getData();
    }
    else if (action === 'archive') {
      await archiveSnippetCall(dialogData.id);

      // re-download data to refresh the grid
      getData();
    }

    setDialogData({action: '', id:'', title:'', desc:'', subDesc:''});

    setDialogOpen(false);
  }
  const handleLabelChanges = (labels) => {
    setTmpLabels(labels)
  }


  return (
      <>
          <div style={{ height: 600, width: '100%' }}>
          <DataGridPro rows={data} columns={columns}
                                  apiRef={apiRef}   
                componentsProps={{
                                cell: {
                                  onMouseEnter: handlePopoverOpen,
                                  onMouseLeave: handlePopoverClose,
                                }
                                }}
              
                slots={{
                  footer: CCCustomFooter,
                }}
                slotProps={{
                  footer: { counter: data.length, titleCounter: "Number of elements" },
                }}
                sx={{
                  [`& .MuiDataGrid-cell:focus`]: {
                    outlineColor: ccColorDarkTeal,
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outlineColor: ccColorDarkTeal,
                  },
                }}
           />

<Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
    <DialogTitle id="dialog-title" sx={{color: ccColorDarkTeal}}>{dialogData.title}</DialogTitle>
    <DialogContent>
        <DialogContentText id="dialog-description">
          {dialogData.desc}
          {dialogData.subDesc && <div className='mt-4'>{dialogData.subDesc}
            </div>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{color: ccColorSecondaryPink}} onClick={() => handleDialogClose('abort')}>Cancel</Button>
        <Button sx={{color: ccColorSecondaryPink}} onClick={() => handleDialogClose(dialogData.action)} autoFocus>
          Confirm
        </Button>
      </DialogActions>

</Dialog>
      
<Popper classes={{ paper: "MuiPopover-paper" }}
      sx={{
        pointerEvents: 'none',
      }}
      open={popupOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
        <Typography sx={{ p: 1 }}>{popupValue}</Typography>
        </Box>
    </Popper>

    <Popover 
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
      open={popupLabelManagerOpen}
      anchorEl={popupLabelManagerAnchorEl}
      onClose={handlePopupLabelManagerClose}
      // disableRestoreFocus
    >
      <Paper sx={{ padding: '10px'}} elevation={4}>
        <Stack spacing={2}>
          <LabelManager data={popupLabelManagerValue} onChange={handleLabelChanges} />
          <Stack direction='row' spacing={1} display="flex" justifyContent="flex-end">
            <CcActionButton onClick={handlePopupLabelManagerClose} >Cancel</CcActionButton>
            <CcActionButton onClick={() => handlePopupLabelManagerClose(true)} >Confirm settings</CcActionButton>
          </Stack>
        </Stack>
        </Paper>
    </Popover>

          </div>

      <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={snackBarClipboardOpen}
      autoHideDuration={1500}
      onClose={handleCloseSnackBarClipboard}
      message="Copied to clipboard"
      action={SnackBarAction}
    />
      </>

  )
}