import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Icon as Iconify } from '@iconify/react';
import {lionIcons}  from './helpersIcons';


export const languagesBlocksMapping = {
    "Mandarin Chinese": ["19968-40959"],
    "Spanish": ["65-90", "97-122", "193", "201", "205", "209", "211", "218", "220", "225", "233", "237", "241", "243", "250", "252"],
    "English": ["65-90", "97-122"],
    "Hindi": ["2304-2431"],
    "Bengali": ["2432-2559"],
    "Portuguese": ["65-90", "97-122", "192", "193", "194", "195", "199", "201", "202", "205", "211", "212", "213", "218", "224", "225", "226", "227", "231", "233", "234", "237", "243", "244", "245", "250"],
    "Russian": ["1040-1103"],
    "Japanese": ["12353-12438", "12449-12538", "19968-40959"],
    "Western Punjabi": ["1536-1791"],
    "Marathi": ["2304-2431"],
    "Telugu": ["3072-3199"],
    "Wu Chinese": ["19968-40959"],
    "Turkish": ["65-90", "97-122", "286", "287", "304", "305", "350", "351"],
    "Korean": ["44032-55203"],
    "French": ["65-90", "97-122", "192", "194", "199", "200", "201", "202", "203", "206", "207", "212", "217", "219", "220", "224", "226", "231", "232", "233", "234", "235", "238", "239", "244", "249", "251", "252"],
    "German": ["65-90", "97-122", "196", "214", "220", "223", "228", "246", "252"],
    "Vietnamese": ["65-90", "97-122", "192", "193", "194", "195", "200", "201", "202", "203", "204", "205", "210", "211", "212", "213", "217", "218", "221", "224", "225", "226", "227", "232", "233", "234", "235", "236", "237", "242", "243", "244", "245", "249", "250", "253"],
    "Tamil": ["2944-3071"],
    "Yue Chinese": ["19968-40959"],
    "Urdu": ["1536-1791"],
    "Javanese": ["43392-43487"],
    "Italian": ["65-90", "97-122", "192", "200", "201", "204", "210", "217", "224", "232", "233", "236", "242", "249"],
    "Egyptian Arabic": ["1536-1791"],
    "Gujarati": ["2688-2815"],
    "Iranian Persian": ["1536-1791"],
    "Bhojpuri": ["2304-2431"],
    "Min Nan Chinese": ["19968-40959"],
    "Hausa": ["65-90", "97-122"],
    "Kannada": ["3200-3327"],
    "Indonesian": ["65-90", "97-122"],
    "Polish": ["65-90", "97-122", "260", "261", "262", "263", "280", "281", "321", "322", "323", "324", "346", "347", "377", "378", "379", "380"],
    "Yoruba": ["65-90", "97-122"],
    "Xiang Chinese": ["19968-40959"],
    "Malayalam": ["3328-3455"],
    "Odia": ["2816-2943"],
    "Maithili": ["2304-2431"],
    "Burmese": ["4096-4255"],
    "Eastern Punjabi": ["1536-1791"],
    "Sundanese": ["7040-7103"],
    "Sudanese Arabic": ["1536-1791"],
    "Algerian Arabic": ["1536-1791"],
    "Moroccan Arabic": ["1536-1791"],
    "Ukrainian": ["1040-1103"],
    "Zulu": ["65-90", "97-122"],
    "Amharic": ["4608-4991"],
    "Igbo": ["65-90", "97-122"],
    "Uzbek": ["1040-1103"],
    "Romani": ["65-90", "97-122"],
    "Filipino": ["65-90", "97-122"],
    "Dutch": ["65-90", "97-122"],
    "Malagasy": ["65-90", "97-122"],
    "Shona": ["65-90", "97-122"],
    "Cebuano": ["65-90", "97-122"],
    "Somali": ["65-90", "97-122"],
    "Serbian": ["1040-1103"],
    "Sinhala": ["3456-3583"],
    "Chichewa": ["65-90", "97-122"],
    "Khmer": ["6016-6143"],
    "Nepali": ["2304-2431"],
    "Akan": ["65-90", "97-122"],
    "Nyanja": ["65-90", "97-122"],
    "Syrian Arabic": ["1536-1791"],
    "Azerbaijani": ["1040-1103"],
    "Greek": ["913-937", "945-969"],
    "Belarusian": ["1040-1103"],
    "Hungarian": ["65-90", "97-122", "193", "201", "205", "211", "214", "212", "336", "218", "220", "368", "225", "233", "237", "243", "246", "337", "250", "252", "369"],
    "Finnish": ["65-90", "97-122", "197", "196", "214", "229", "228", "246"],
    "Kazakh": ["1040-1103"],
    "Czech": ["65-90", "97-122", "193", "268", "270", "201", "205", "327", "211", "344", "352", "356", "366", "381", "225", "269", "271", "283", "237", "328", "243", "345", "353", "357", "367", "382"],
    "Romanian": ["65-90", "97-122", "194", "206", "258", "258", "259", "226", "238", "537", "539", "536", "538"],
    "Chewa": ["65-90", "97-122"],
    "Bulgarian": ["1040-1103"],
    "Serbo-Croatian": ["65-90", "97-122", "268", "269", "262", "263", "272", "273", "352", "353", "381", "382"],
    "Kurdish": ["1536-1791"],
    "Madurese": ["65-90", "97-122"]
  }
  
export const base64ToArrayBuffer = (base64) => {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export const CcFontLoader = (props) => {
  //download font names from the api
  //if these fonts are not in the local storage it will download the font data and save them to the local storage
  useEffect(() => {
    processFonts()
  }, [])

  const getFonts = async() => {
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/fontsWithStyles`;

      const response = await axios.get(url);

      return response.data

    } catch (error) {
    }
    finally {
    }

  }
  const getFontData = async(fontId, familyName, style) => {
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/User/Font/${fontId}?weight=${style.weight}&italic=${style.italics}`;

      const response = await axios.get(url);

      return [getFontStyleId(familyName, style), response.data]

    } catch (error) {
    }
    finally {
    }

  }

  const getFontStyleId = (familyName, style) => {
    return 'font-' + familyName + '|||' +  style.weight + '-' + style.italics;

  }
  const parseFontStyleId = (id) => {
    const idParts = id.split('|||');

    return [idParts[0].replace('font-',''), idParts[1].split('-')[0], idParts[1].split('-')[1]]

  }

  const processFonts = async() => {
    const fonts = await getFonts();
    fonts.forEach(f => {
      const styles = JSON.parse(f.styles)
      styles.forEach(s => {
        const currentId = getFontStyleId(f.familyName, s);
        if (localStorage.getItem(currentId) == null) {
          getFontData(f.fontId, f.familyName, s).then((([currentId, fontData]) => {
            localStorage.setItem(currentId, fontData);
            const [familyName, weight, italic] = parseFontStyleId(currentId)
            
            const font = new FontFace(familyName, base64ToArrayBuffer(fontData), {
              weight: weight,
              style: italic === 'true' ? 'italic' : 'normal'
            });
            document.fonts.add(font);          
          }))
        }
        else {
          const fontData = localStorage.getItem(currentId)
          const [familyName, weight, italic] = parseFontStyleId(currentId)

          const font = new FontFace(familyName, base64ToArrayBuffer(fontData), {
            weight: weight,
            style: italic === 'true' ? 'italic' : 'normal'
          });
          document.fonts.add(font);
          //console.log(font)
      }
      })
    })
}
  return <></>
}


export const FontStyleMenu = (props) => {
  /*
  props:

  styles: string as from our API
  selectedStyle: the style {weight: , italics: } to show as selected
  */
  const [menuItems, setMenuItems] = useState([])
  const [selectedStyle, setSelectedStyle] = useState('')
  const [allStyles, setAllStyles] = useState([])// holds the  array [{weight: 400, italics: false}] passed via props

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleWeightClicked = (style) => {
    setAnchorEl(null);
    //legacy: style could have style.id or style.weight properties
    if (props.onChange) props.onChange({weight: style.id ?? style.weight, italics: style.italics})
  };

const getFallbackStyle = (styles) => {
  //when no weight is selected we want to provide the w=400 one or the closest possible to that one

  if (styles.length === 1) return styles[0];

  const weights = styles.map(s => s.weight)
  const orderedWeights = weights.map(w => {return {weight: w, distance: Math.abs(400 - w)}})

  orderedWeights.sort((a,b) => a.distance - b.distance);

  //we take the styles with the desired weight
  const stylesWithTargetWeight = styles.filter(s => s.weight === orderedWeights[0].weight)

  //if there is only one style we return that one
  //if  there are two styles we return the one not italic
  if (stylesWithTargetWeight.length === 1) return stylesWithTargetWeight[0]
  else return stylesWithTargetWeight.find(s => s.weight === orderedWeights[0].weight && s.italics === false)

}  

  const getNameFromWeight = (w) => {
    switch(w) {
      case 100:
        return <div className="text-ccColorDarkTeal font-thin">Thin (w: {w})</div>                                         
      case 200:
        return <div className="text-ccColorDarkTeal font-extralight">Extra Light (w: {w})</div>                                         
      case 300:
        return <div className="text-ccColorDarkTeal font-light">Light (w: {w})</div>                                         
      case 400:
        return <div className="text-ccColorDarkTeal font-normal">Normal (w: {w})</div>                                         
      case 500:
        return <div className="text-ccColorDarkTeal font-medium">Medium (w: {w})</div>                                         
      case 600:
        return <div className="text-ccColorDarkTeal font-semibold">Semi Bold (w: {w})</div>                                         
      case 700:
        return <div className="text-ccColorDarkTeal font-bold">Bold (w: {w})</div>                                         
      case 800:
        return <div className="text-ccColorDarkTeal font-extrabold">Extra Bold (w: {w})</div>                                         
      case 900:
        return <div className="text-ccColorDarkTeal font-black">Black (w: {w})</div>                                         
      default: 
        return <div className="text-ccColorDarkTeal">{w} custom weight</div>                                         
    }
  }


  useEffect(() => {
    //console.log(props.styles)
    if (Array.isArray(props.styles) && props.styles.length === 0) return;
    
    if (props.styles) {
      const styles = JSON.parse(props.styles);
      //console.log(styles)
      //console.log(props.selectedStyle)
      setAllStyles(styles)
      styles.sort((a,b) => a.weight - b.weight)
      setMenuItems(styles.map(s => {return {id: s.weight, name: getNameFromWeight(s.weight), italics: s.italics}}))

      if (props.selectedStyle === undefined || props.selectedStyle[0] === "undefined") {
        const fallbackStyle = getFallbackStyle(styles);
        //console.log(fallbackStyle)
        setSelectedStyle(fallbackStyle)
        if (props.onChange) props.onChange(fallbackStyle)
      }
      else {
        setSelectedStyle({weight: props.selectedStyle[0], italics: props.selectedStyle.length > 1})
        //setItalicBtnEnabled(italicExists(styles, props.selectedWeight))
      }
    }
  }, [props.styles, props.selectedStyle])

  
  return <div className="flex flex-row gap-x-0 cursor-pointer">
            <div className="w-[110px]">
              <div className="p-2 border rounded border-gray-200 hover:bg-gray-200"
                onClick={handleClick}
              >
                <div className="flex flex-row text-sm gap-x-1 items-center">Weight {selectedStyle.weight} {selectedStyle.italics ? <Iconify icon="solar:text-italic-circle-bold" className="text-ccColorTeal" /> : ''}</div>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleWeightClicked(selectedStyle)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {menuItems.map(w => <MenuItem onClick={() => handleWeightClicked(w)}>
                                <div className="flex flex-row gap-x-1 w-full">
                                  <div>{w.name}</div>
                                  <div className="grow min-w-1"></div>
                                  {w.italics && <div className="w-6 flex justify-end items-center"><Iconify icon="solar:text-italic-circle-bold" className="text-ccColorTeal" /></div>}
                                </div>
                                </MenuItem>)}
                
              </Menu>
            </div>
    </div>
     

}

export const FontPreviewViewer = (props) => {
  const canvasRef = useRef(null)
  const canvasWidth = 40 //120;
  const canvasHeight = 56 //170;
  const fontSize = 28 //86
  
    /*
    props:
      glyph: the glyph object as defined in the opentype.js lib
    
    */

    useEffect(() => {
      if (props.glyph) {
        //console.log(props.glyph)
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.fillStyle = '#ccc'
        context.fillRect(0, 0, context.canvas.width, context.canvas.height)
  

        
        const path = props.glyph.getPath(0, 0, fontSize)

        const bb = path.getBoundingBox()
        //console.log(bb)
        const width = bb.x2 - bb.x1;
        const height = bb.y2 - bb.y1;
        const offsetX = (canvasWidth - width) / 2
        const offsetY = (canvasHeight - height) / 2
        //console.log(offsetX)
        //console.log(offsetY)

        //props.glyph.draw(context, offsetX, canvasHeight - offsetY, fontSize)
        //props.glyph.draw(context, offsetX, 120, fontSize)
        props.glyph.draw(context, offsetX, 40, fontSize)
        
      }

    }, [props.glyph])

  return <div>
          <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
          {/*<div style={{width: canvasWidth}} className='text-sm text-center'>{props.glyph.name}</div>*/}
  </div>
}
