import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from "react"
import { Transition } from '@headlessui/react'
import { Icon as Iconify } from '@iconify/react';
import {Button, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
            IconButton, Tooltip} from '@mui/material';
import {ccColorDarkTeal, lionObjectsColors}  from "../components/helpersStyles";
import {jsonDateTimeToDateOnlyPretty} from "../commonComponents/helperDateTime";
import {lionIcons} from "../components/helpersIcons";


import Popover from '@mui/material/Popover';

import { Slide, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const Slider = (props) => {
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
  }
  const slideImages = [
    {
      url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
      caption: 'Slide 1'
    },
    {
      url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
      caption: 'Slide 2'
    },
    {
      url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
      caption: 'Slide 3'
    },
  ];

  return   <div className="slide-container">
            <Zoom scale={1.4}>
            {props.images.map((slideImage, index)=> {
                return <div key={index}>
                  <div style={{ ...divStyle, width:slideImage.width, height:slideImage.height, 'backgroundImage': `url(data:image/jpg;base64,${slideImage.imgBase64})` }}>
                  </div>
                </div>
              })} 
            </Zoom>
          </div>
}

export const ImagePopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton sx={{color: '#fff', backgroundColor: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={handleClick}><Iconify icon="mdi:magnify" width="16" height="16" /></IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='flex flex-col items-center font-bold gap-4 p-2 bg-slate-200'>
          <div>Image Natural Size</div>
          <img src={props.imgSrc}  />
        </div>
      </Popover>
    </div>
  );
}
export const ImagePopoverPolling = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imgArr, setImgArr] = useState([])

  useEffect(() => {
    const open = Boolean(anchorEl);

    if (open) getImages(props.templateId)
  }, [anchorEl])

  const getImages = async (templateId) => {
    try {  
      setImgArr([])
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/votes/previewImages/${templateId}`;
      
      const response = await axios.get(url);
      const data = response.data;
      setImgArr(data);


    } catch (error) {
    console.log(error);
    }
}


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <div>
      <IconButton sx={{color: '#fff', backgroundColor: ccColorDarkTeal, ":hover": {backgroundColor: '#8ED2D7'}}} onClick={handleClick}><Iconify icon="mdi:magnify" width="16" height="16" /></IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='flex flex-col items-center font-bold gap-4 p-2 bg-slate-200'>
          <div>Image Natural Size Polling</div>
          {imgArr.length > 0 &&
            <div style={{width: `${imgArr[0].width}px`}}>
            <Slider images={imgArr} />
          </div>
          }
        </div>
      </Popover>
    </div>
  );
}

export default function TemplateGallery(props) {
    /*
    props:
    productTypeId: show templates of a specific type
    selectedTemplateId: 
    */
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(0);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({action: '', id:'', title:'', desc:''});

    const navigate = useNavigate();

    useEffect(() => {
        getTemplates();
    }, [props.productTypeId])


    const getTemplates = async () => {
        try {  
        let url = `${process.env.REACT_APP_LION_API_URL}/Templates/previewImages`;
        if (props.productTypeId !== undefined) url += `/${props.productTypeId}`;
        
        const response = await axios.get(url);
        const data = response.data;
        //console.log(data)

        if (props.selectedTemplateId === undefined) data[0].selected = true; // pre-select the first template
        else {
            data.find(t => t.templateId === props.selectedTemplateId).selected = true
        }

        /*
        for (let i=0; i<data.length; i++) {
        data[i].metadata = JSON.parse(data[i].metadata)
        // data[i].modelJson = JSON.parse(data[i].modelJson)
        }
        if (props.onChange !== undefined) {
            props.onChange(data[0])
            if (props.selectedTemplateId === undefined) props.onChange(data[0]);
            else {
            props.onChange(data.find(t => t.templateId === props.selectedTemplateId))
            }
        }
        */
        setTemplates(data);

        } catch (error) {
        console.log(error);
        }
    }
    
    const handleEditTemplate = (tId) => {
        navigate(`/templates/edit/${props.productTypeId}/${tId}`)
    }
    const handleEditNewElementFromTemplate = (tId, dataConId) => {
        if (props.productTypeId === 12) navigate(`/ph/timersFromTemplate/${tId}`)
        if (props.productTypeId === 9) navigate(`/ph/textImagesFromTemplate/${tId}`)
        if (props.productTypeId === 1) navigate(`/ph/generatorFromTemplate/${tId}/${dataConId}`)
        if (props.productTypeId === 5) navigate(`/ph/votesFromTemplate/${tId}`)
        if (props.productTypeId === 6) navigate(`/ph/clickcounterFromTemplate/${tId}`)
        if (props.productTypeId === 7) navigate(`/ph/moodmeterFromTemplate/${tId}`)
        if (props.productTypeId === 14) navigate(`/ph/persAnimationsFromTemplate/${tId}`)
    }
    const handleArchiveTemplate = (tId) => {
        // we open the confirmation dialog
        const dialogNewData = {action: 'archive', id:tId, title:'Archive Template', desc:'Are you sure you want to archive this template?'};
        setDialogData(dialogNewData);
        setDialogOpen(true);
    }
    const handleDuplicateTemplate = (tId) => {
      // we open the confirmation dialog
      const dialogNewData = {action: 'duplicate', id:tId, title:'Duplicate Template', desc:'Are you sure you want to duplicate this template?'};
      setDialogData(dialogNewData);
      setDialogOpen(true);
  }
  
  const archiveTemplateCall = async (snippetId) => {
      try {
        const url = `${process.env.REACT_APP_LION_API_URL}/Templates/archive/${snippetId}`;
        const response = await axios.get(url);
        // const url = `api/Snippets/delete/${snippetId}`;
        // const response = await fetch(url, { method: "GET", });
        // response.data = await response.json();
          
      } catch (error) {
        console.log(error);
      }
  
    }      
  const duplicateTemplateCall = async (templateId) => {
    try {
      const url = `${process.env.REACT_APP_LION_API_URL}/Templates/duplicate/${templateId}`;
      const response = await axios.get(url);
        
    } catch (error) {
      console.log(error);
    }

  }    

  const handleDialogClose = async(action) => {
    if (action === 'delete') {
        //await deleteSnippetCall(dialogData.id);

      // re-download data to refresh the grid
      //getTemplates();
    }
    else if (action === 'archive') {
      await archiveTemplateCall(dialogData.id);

      // re-download data to refresh the grid
      getTemplates();
    }
    else if (action === 'duplicate') {
      await duplicateTemplateCall(dialogData.id);

      // re-download data to refresh the grid
      getTemplates();
    }

    setDialogData({action: '', id:'', title:'', desc:''});

    setDialogOpen(false);
  }
    
    return <div>
            <div className='grid grid-cols-4 gap-x-2 gap-y-5 h-80 overflow-auto'>
                {templates.map(t => <div className='flex flex-col gap-0'>
                            <div className='flex flex-row justify-center'>{t.templateName}</div>

                            <div className=' relative w-full h-[280px] flex flex-row items-center justify-center bg-gray-200 p-2 rounded-md'
                            onMouseEnter={() => setSelectedTemplateId(t.templateId)}
                            onMouseLeave={() => setSelectedTemplateId(0)}
                        >
                            <img style={{height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%'}} src={t.previewURL} />
                            {/*t.templateId === selectedTemplateId &&
                            <div className='absolute flex flex-row justify-center items-center w-full h-full rounded-md' style={{backgroundColor: 'rgba(120, 120, 120, 0.7)'}}>
                                <button className=' text-white bg-lionGradient2Via hover:bg-lionGradient2From focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm p-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Create Element</button>
                            </div>*/
                            }   
                             <Transition className=" absolute w-full h-full"
                                show={t.templateId === selectedTemplateId}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                //enter="transition transform duration-1000"
                                //enterFrom="translate-y-0"
                                //enterTo="translate-y-[150px]"
                                //leave="transition transform duration-150"
                                //leaveFrom="translate-y-1/2"
                                //leaveTo="translate-y-0"
                            >
                                <div className='absolute w-full top-[65%] h-[35%] rounded-md' style={{backgroundColor: 'rgba(70, 70, 70, 0.92)'}}>
                                    <div className='flex flex-col gap-2 p-1'>
                                        <div className='flex flex-row gap-2 text-white items-center'>
                                            <Iconify icon="iconoir:calendar" width="26" height="26" />
                                            <div >{jsonDateTimeToDateOnlyPretty(t.created)}</div>
                                            <div className='flex grow' ></div>
                                            <Tooltip title="Edit template" placement='top'><IconButton sx={{color: '#fff', backgroundColor: '#5186A3', ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleEditTemplate(t.templateId)}><Iconify icon={lionIcons.Icon_Actions_Edit} width="16" height="16" /></IconButton></Tooltip>
                                            <Tooltip title="New element from this template" placement='top'><IconButton sx={{color: '#fff', backgroundColor: '#5186A3', ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleEditNewElementFromTemplate(t.templateId, t.dataConnectionId)}><Iconify icon="fluent:new-20-filled" width="16" height="16" /></IconButton></Tooltip>
                                            <Tooltip title="Duplicate this template" placement='top'><IconButton sx={{color: '#fff', backgroundColor: '#5186A3', ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleDuplicateTemplate(t.templateId)}><Iconify icon="heroicons:document-duplicate" width="16" height="16" /></IconButton></Tooltip>
                                            <Tooltip title="Archive" placement='top'><IconButton sx={{color: '#fff', backgroundColor: '#5186A3', ":hover": {backgroundColor: '#8ED2D7'}}} onClick={() => handleArchiveTemplate(t.templateId)}><Iconify icon={lionIcons.Icon_Actions_Archive} width="16" height="16" /></IconButton></Tooltip>
                                            {props.productTypeId === 5 ? <ImagePopoverPolling templateId={t.templateId}></ImagePopoverPolling>
                                              : <ImagePopover imgSrc={t.previewURL}></ImagePopover>  
                                          }
                                        </div>
                                        <div className='flex flex-row flex-nowrap gap-1'>
                                        {t.labels.map(l => <Chip  label={l.labelName} size='small'
                                                sx={{color: 'white', minWidth:70, maxWidth:100, backgroundColor: lionObjectsColors.Color_SnippetLabel}}/>)}
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                            </div>





                        </div>
                )}
            </div>

            <Dialog onClose={() => handleDialogClose('abort')} open={dialogOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
    <DialogTitle id="dialog-title">{dialogData.title}</DialogTitle>
    <DialogContent>
        <DialogContentText id="dialog-description">
          {dialogData.desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDialogClose('abort')}>Cancel</Button>
        <Button onClick={() => handleDialogClose(dialogData.action)} autoFocus>
          Confirm
        </Button>
      </DialogActions>

</Dialog>

    </div>
    }  